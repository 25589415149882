import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfigService } from './app-config/app-config.service';
import { EmailDetails } from '../../models/common/send-email/send-email';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  noticeDataForLitigationVariable : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
  constructor(
    private httpClient: HttpClient
  ) { }

  commonAPIBaseUrl = AppConfigService.ReportServiceURL + '/api/common/';

  sendEmail(emailDetails: EmailDetails): Observable<any> {
    return this.httpClient.post(this.commonAPIBaseUrl + 'sendemail', emailDetails);
  }

}

