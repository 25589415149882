import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatSnackBar, ErrorStateMatcher, MatDialogRef } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ReturnsService } from '../services/Returns/gstr-one.service';
import { OTPUserDetails } from '../models/get/get-file-returns/get-otp-user-details';
import { GlobalSrvConstantsService } from '../services/global/global-constants/global-srv-constants.service';
import { GenerateAuthTokenService } from '../services/common/gstin-session/generate-auth-token.service';

@Component({
  selector: 'app-otp-confirmation-dialog',
  templateUrl: './otp-confirmation-dialog.component.html',
  styleUrls: ['./otp-confirmation-dialog.component.css']
})
export class OtpConfirmationDialogComponent implements OnInit {
  otpUserDetails: OTPUserDetails = {} as OTPUserDetails;

  otpFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(("[0-9]\\d{5}")),
  ]);
  matcher = new MyErrorStateMatcher();
  EnteredOTP:any;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private returnsService: ReturnsService,
    private snackBar: MatSnackBar,
    public  generateAuthTokenService: GenerateAuthTokenService,
    public dialogRef: MatDialogRef<OtpConfirmationDialogComponent> 
    ) {dialogRef.disableClose = true; }

  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }


  ngOnInit() {
  }

  onVerify(OTP: string) {
    this.otpUserDetails.EntityGstin = this.data.otpUserDetails.EntityGstin;
    this.otpUserDetails.SessionId = this.data.otpUserDetails.SessionId;
    this.otpUserDetails.OTP = OTP;
    this.generateAuthTokenService.getAuthenticationToken(this.otpUserDetails).subscribe((res) => {
     
      this.generateAuthTokenService.activatedGstin.next(res);
      if (res) {
        if(res.data.statusCd == '1'){
          this.dialogRef.close(true);
          
          this.snackBar.open('Gstin Session Activated Successfully', 'OK', {
            duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['success-snackbar']
          })
        }
        else{
          this.dialogRef.close(true);
          this.snackBar.open('Gstin Session Activation Failed', 'OK', {
            duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['danger-snackbar']
          })
        }
      }

      else {
        this.snackBar.open(res.message, 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['denger-snackbar']
        });
      }

    },
      error => {
        this.snackBar.open(
          'Error | Status: ' + error.status + ' | ' + error.error,
          'OK',
          {
            duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['danger-snackbar']
          }
        );
      });
      this.generateAuthTokenService.RefreshOTPSessionPage.next(true);
  }
  closePopup(){
    this.dialogRef.close(true);
    }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

