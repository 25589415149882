import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { downloadPDFReport } from '../../models/post/pst-md-file-returns/post-file-return-data';
import { AppConfigService } from '../common/app-config/app-config.service';
import { GlobalApiService } from '../global/global-api/global-api.service';
import { GSTR1SummaryDownloadViewModel, GSTR1SummaryResponseModel, GSTR3BDataViewModel, GSTR3BPrepareMode, PrepareReturnModel } from './returns-model.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GSTR3bService {
  constructor(private _httpClient: HttpClient,) { }
  gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;

  getFilterData(returnType: string): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR1Returns/FilterData' + '/' + returnType
    );
}


getGstinUserName(GSTIN: string): Observable<any> {
  return this._httpClient.get(
    this.gstrServiceBaseUrl + '/GSTR3B/GetEntityName' + '/' +  GSTIN
  );
}

GetRCMITCInNextRP(): Observable<any> {
  return this._httpClient.get(
    this.gstrServiceBaseUrl + '/GSTR3B/GetRCMITCInNextRP'
  );
}

  Refresh(gstr3BPrepareMode:GSTR3BPrepareMode): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR3B/GetForm3Bdata' , gstr3BPrepareMode
    );
  }

  downloadGSTR3BSummary(gstr3BPrepareMode:GSTR3BPrepareMode): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR3B/GetForm3BdataSummary', gstr3BPrepareMode, { responseType: 'blob' }
    ).pipe(
      map((response: any) =>{
        return response;
      })
    );
  }

  PrepareGSTR3BDownload(PrepareReturns: any): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR3B/PrepareGSTR3BDownload', PrepareReturns, { responseType: 'blob' }
    );
  }

  DownloadGSTR3B_ITCRCMBreakUp(PrepareReturns: any): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR3B/GSTR3B_ITCRCMBreakUp', PrepareReturns, { responseType: 'blob' }
    );
  }


  PrepareBoltonGSTR3BDownload(PrepareReturns: any): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR3B/GetPreviewPreparedReturns', PrepareReturns, { responseType: 'blob' }
    ).pipe(
      map((response: any) =>{
        return response;
      })
    );
  }

  saveGstr3bData(data: any): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR3B/SaveGSTR3B?GSTIN=' + data.gstin + '&returnPeriod=' + data.returnPeriod + '&prepareData=' + data.prepareData + '&Source=' + data.source,{
      });
  }

  saveGstr3bBoltonData(data: any): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR3B/InsertForm3Bdata',data
    );
  }

  CheckDataisPresentIn2Band3B(PrepareReturns: any): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR3B/CheckDataisPresentIn2Band3B', PrepareReturns
    );
  }

  getGstr3bSaveStatus(data): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR3B/GetGSTR3BSaveStatus?GSTIN=' + data.EntityGstin + '&referenceId=' + data.RefId)
  } 

  getStateNames():Observable<any>{
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR3B/GetMasterStateName')
  }
  generateReconAcceptedReport(gstinPeriod: any): Observable<any> {
    return this._httpClient.post(this.gstrServiceBaseUrl + '/ReturnsReport/DownloadAcceptedReconReport',gstinPeriod);
}

DownloadVendorReport(PrepareReturns: any): Observable<any> {
  return this._httpClient.post(
    this.gstrServiceBaseUrl + '/GSTR3B/VendorReport', PrepareReturns, { responseType: 'blob' }
  );
}
generatePDF(GSTR1SummaryDownload: any): Observable<any> {
  return this._httpClient.post(
    this.gstrServiceBaseUrl + '/GSTR1Returns/ReturnSummaryPdfDownload', GSTR1SummaryDownload
  );
}
GetGSTR3BLiab(gstr3BPrepareMode:GSTR3BPrepareMode): Observable<any> {
  return this._httpClient.post(
    this.gstrServiceBaseUrl + '/GSTR3B/GetGSTR3BLiab' , gstr3BPrepareMode
  );
}

}
