import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../common/app-config/app-config.service';
import { GetGSTR2AReportViewModel, GetGSTR2ARequetViewModel } from 'src/app/shared/models/global/boew-eway/get-boew-eway-search';
import { GlobalApiService } from '../global/global-api/global-api.service';

@Injectable({
  providedIn: 'root'
})
export class DataRetreivalService {

  apiGstrServiceUrl = AppConfigService.ApiGstrServiceUrl;
  constructor(private _httpClient: HttpClient ) { }

  postDataRetreivalIMSDetails(requestDetails): Observable<any> {
    
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/IMS/InsertIMSGetINV',requestDetails
    );
}

  postDataRetreivalDetails(requestDetails): Observable<any> {
    
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/GSTR1Returns/GetGSTR1EInvoice',requestDetails
    );
}
postDataRetreivalDetailsGSTR6A(requestDetails): Observable<any> {
  
  return this._httpClient.post(
    this.apiGstrServiceUrl + '/GSTR6A/GetGSTR6A',requestDetails
  );
}

postDataRetreivalDetailsGSTR6(requestDetails): Observable<any> {
  
  return this._httpClient.post(
    this.apiGstrServiceUrl + '/GSTR6A/GetGSTR6',requestDetails
  );
}

  public getDataRetreivalDetails(data:any): Observable<any> {
    return this._httpClient.post(
        this.apiGstrServiceUrl + '/file/ewaydownload', data
    )
  }

  public getDataRetreivalStatus(requestDetails:any): Observable<any> {
    return this._httpClient.post(
        this.apiGstrServiceUrl + '/GSTR1Returns/CheckFileGenerationInprogress', requestDetails
    )
  }
  DownloadGSTR6AReport(PrepareReturns: any): Observable<any> {
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/GSTR6A/DownloadGSTR6AReport', PrepareReturns, { responseType: 'blob' }
    );
  }
  DownloadDataRetrievalGSTR1Report(PrepareReturns: any): Observable<any> {
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/DataRetrieval/DataRetrievalGSTR1InvoicesDownload', PrepareReturns, { responseType: 'blob' }
    );
  }

  generateReport(requestDetails): Observable<any> {
    
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/DataRetrieval/GenerateReport',requestDetails
    );
}

generatePDFReport(requestDetails): Observable<any> {
    
  return this._httpClient.post(
    this.apiGstrServiceUrl + '/DataRetrieval/GeneratePDFReport',requestDetails
  );
}

  getGenerateReportDetails(getReportViewModel: GetGSTR2AReportViewModel): Observable<any> {
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/DataRetrieval/GetDownloadRequest', getReportViewModel
    )
  }

  getGenerateReportPDFDetails(getReportViewModel: GetGSTR2AReportViewModel): Observable<any> {
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/DataRetrieval/GetDownloadRequest', getReportViewModel
    )
  }

  getDataRetreivalDetailsGSTR9getDetails(requestDetails): Observable<any> {

    return this._httpClient.post(
        this.apiGstrServiceUrl + '/GSTR9/GetGSTR9Details', requestDetails
    );
  }

  getDataRetreivalDetailsGSTR9Table8A(requestDetails): Observable<any> {

    return this._httpClient.post(
        this.apiGstrServiceUrl + '/GSTR9/GetGSTR98ADetails', requestDetails
    );
  }

  getDataRetreivalDetailsGSTR8getSummaryDetails(requestDetails): Observable<any> {    
    return this._httpClient.post(
        this.apiGstrServiceUrl + '/GetGSTR8/GSTR8GetSummaryDetails', requestDetails
    );
}

  getDataRetreivalDetailsGSTR1(requestDetails): Observable<any> {
  
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/DataRetrieval/GetDRGSTR1Request',requestDetails
    );
  }
  postDataRetreivalDetailsGSTR1(requestDetails): Observable<any> {
    
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/DataRetrieval/DataRetrievalInvoices',requestDetails
    );
  }

  DownloadReconForceMatchReport(uploadType:string,requestID: number): Observable<any> {
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/GSTR2A/recon/download?requestID='+ requestID + '&actionType='+ uploadType);
    }
    
    downloadReconFile(fileID,requestID,actionType): Observable<any> {
      return this._httpClient.get(
        this.apiGstrServiceUrl + '/GSTR2A/recon/download?fileID='+ fileID + '&requestID='+requestID + '&actionType=' + actionType, {
        responseType: 'blob'
      });
    }

    downloadAutoReconFile(actionType,reconType): Observable<any> {
      return this._httpClient.get(
        this.apiGstrServiceUrl + '/GSTR2A/autorecon/download?actionType=' + actionType+ '&reconType=' + reconType, {
          responseType: 'blob'
      });
    }

    DataRetrievalInvoicesForSummary(requestDetails): Observable<any> {
    
      return this._httpClient.post(
        this.apiGstrServiceUrl + '/DataRetrieval/DataRetrievalInvoicesForSummary',requestDetails
      );
    }

    public checkDataRetrievalGetStatus(requestDetails:any): Observable<any> {
      return this._httpClient.post(
          this.apiGstrServiceUrl + '/GSTR1Returns/CheckDataRetrievalGetStatus', requestDetails
      )
    }
    TerminateGSTR2ARequest(requestID: number, returnType : string): Observable<any> {
      return this._httpClient.get(
        this.apiGstrServiceUrl + '/GSTR2A/TerminateGSTR2ARequest?requestID='+ requestID  + '&returnType='+ returnType);
      }

      getDataRetreivalDetailsGSTR7getDetails(requestDetails): Observable<any> {

        return this._httpClient.post(
            this.apiGstrServiceUrl + '/GSTR7/GetGSTR7Details', requestDetails
        );
      }
      postDataRetreivalDetailsForExport(requestDetails): Observable<any> {
    
        return this._httpClient.post(
          this.apiGstrServiceUrl + '/DataRetrieval/GenerateDataRetrivalExcelExport',requestDetails
        );
    }
    getExcelExportGenerateReportDetails(getReportViewModel: GetGSTR2AReportViewModel): Observable<any> {
      return this._httpClient.post(
        this.apiGstrServiceUrl + '/DataRetrieval/GetDataRetrivalExcelExportRequest', getReportViewModel
      )
    }

    getDataRetreivalDetailsGSTR7SummaryDetails(requestDetails): Observable<any> {
      return this._httpClient.post(
          this.apiGstrServiceUrl + '/GSTR7/GSTR7GetSummaryDetails', requestDetails
      );
    }
    getDataRetreivalDetailsGSTR6SummaryDetails(requestDetails): Observable<any> {
      return this._httpClient.post(
          this.apiGstrServiceUrl + '/GSTR6A/GSTR6GetSummaryDetails', requestDetails
      );
    }
}
