import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../common/app-config/app-config.service';
import { GlobalApiService } from '../../global/global-api/global-api.service';

@Injectable({
    providedIn: 'root'
})
export class GetSrvSharedService {

    baseUrl = GlobalApiService.GLOBALAPIURL;
    oganizationDetail = GlobalApiService.ORGANIZATIONDATA;
    gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;
    constructor(private _httpClient: HttpClient) { }

    getGstinFPList(): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/bo/inv/filter'
        );
    }
    GetEnableMultiGSTINDocumentSearch(): Observable<any> {
        return this._httpClient.get(
          this.gstrServiceBaseUrl + '/GSTR3B/GetEnableMultiGSTINDocumentSearch'
        );
    }
    getAllGstinFPList(): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/bo/inv/filterAll'
        );
    }

    getRecipientGstin(returnType,GSTIN): Observable<any>{
        return this._httpClient.get(
            this.gstrServiceBaseUrl + '/GSTR1Returns/FilterData' + '/' + returnType+ '/' + GSTIN
          );  
    }
    getFilterData(returnType : string): Observable<any> {
        return this._httpClient.get(
          this.gstrServiceBaseUrl + '/GSTR1Returns/FilterData' + '/' + returnType
        );
    }
    
    getFinancialYear(): Observable<any> {
        return this._httpClient.get(
          this.gstrServiceBaseUrl + '/GSTR3B/GetFinancialYear'
          );
      }

      getVendorGstin():Observable<any> {
        return this._httpClient.get(
          this.gstrServiceBaseUrl + '/GSTR1Returns/FilterGstin'
          );
      }

    getAdvancedSearchFields(): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/bo/inv/advancefilter'
        );
    }

    getOrganizationDetails(): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/bo/inv/Organizationconfig'
        );
    }

    getGstinFPTransporterGstinList(): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/ew/eway/filter'
        );
    }

    getInvLevelCustomerDetails(selectedGSTINandFP): Observable<any> {
        return this._httpClient.post(
            this.baseUrl + '/bo/inv/customer', selectedGSTINandFP
        );
    }

    getEwayLevelCustomerDetails(selectedGSTINandFP): Observable<any> {
        return this._httpClient.post(
            this.baseUrl + '/ew/eway/customer', selectedGSTINandFP
        );
    }

    getColumnList(tableType: string): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/bo/inv/column/list?tableType=' + tableType
        );
    }

    getInvTypeDetails(selectedGSTINandFP): Observable<any> {
        return this._httpClient.post(
            this.baseUrl + '/bo/inv/invoicetype', selectedGSTINandFP
        );
    }

    getFYandPeriod(type:string): Observable<any> {
        return this._httpClient.get(
          this.gstrServiceBaseUrl + '/GSTR2A/GetFYandPeriod' + '/' + type
          );
      }
}
