import { Injectable } from '@angular/core';
import { GlobalSrvConstantsService } from '../../global/global-constants/global-srv-constants.service';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private snackBar: MatSnackBar,
  ) { }

  showValidationAlert(message: string){
    this.snackBar.open(message, 'OK', {
      duration: GlobalSrvConstantsService.SNACKBARDURATION,
      panelClass: ['alert-snackbar']
    });
  }

  showErrorAlert(message: string){
    this.snackBar.open(message, 'OK', {
      duration: GlobalSrvConstantsService.SNACKBARDURATION,
      panelClass: ['danger-snackbar']
    });
  }

  showSuccessAlert(message: string){
    this.snackBar.open(message, 'OK', {
      duration: GlobalSrvConstantsService.SNACKBARDURATION,
      panelClass: ['success-snackbar']
    });
  }
}
