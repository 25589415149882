import { EntityGSTINSelected } from "../global/boew-eway/get-boew-eway-search";

export class ReconBase {
    PeriodOrYTD:number;
    EntityGstin  : EntityGSTINSelected[];
    VendorGstin  : EntityGSTINSelected[];
    TransactionType : string;
    Currentpage : number;
    PageSize : number;
    ReturnType : string;
}