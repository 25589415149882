import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../common/app-config/app-config.service';
import { GlobalApiService } from '../global/global-api/global-api.service';
import { CashItcBalance } from '../../models/post/pst-md-file-ledger/post-file-ledger-data';

@Injectable()
export class InputServiceDistributionService {
  constructor(private _httpClient: HttpClient) { }
  ewayBillAPIBaseUrl = AppConfigService.ApiEnvoiceEwaybillServiceUrl;
  gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;

  getISDRequests(gstin, period, documetnType, itcType): Observable<any> {
    const data = new FormData();
    data.append('gstin', gstin);
    data.append('period', period);
    data.append('documetnType', documetnType);
    data.append('itcType', itcType);
    return this._httpClient.post(`${this.gstrServiceBaseUrl}/isd/GetISDRequests`, data);
  }

  getGenerateInvoices(gstin, period, itc): Observable<any> {
    const data = new FormData();
    data.append('gstin', gstin);
    data.append('period', period);
    data.append('itc', itc);
    return this._httpClient.post(`${this.gstrServiceBaseUrl}/isd/GetGenerateInvoices`, data);
  }

  getISDPlans(gstin, period, isPrevMonth): Observable<any> {
    const data = new FormData();
    data.append('gstin', gstin);
    data.append('period', period);
    data.append('isPrevMonth', isPrevMonth);
    return this._httpClient.post(`${this.gstrServiceBaseUrl}/isd/GetISDPlans`, data);
  }

  saveISDPlans(isdPlans, comments): Observable<any> {
    const data = new FormData();
    data.append('isdPlans', JSON.stringify(isdPlans));
    data.append('comments', comments);
    return this._httpClient.post(`${this.gstrServiceBaseUrl}/isd/SaveISDPlans`, data);
  }

  saveDistributeGSTTax(gstin, period, documetnType, itcType, selectedInvoices, distributedGSTBranch): Observable<any> {
    const data = new FormData();
    data.append('gstin', gstin);
    data.append('period', period);
    data.append('documetnType', documetnType);
    data.append('itcType', itcType);
    data.append('selectedInvoices', JSON.stringify(selectedInvoices));
    data.append('distributedGSTBranch', JSON.stringify(distributedGSTBranch));
    return this._httpClient.post(`${this.gstrServiceBaseUrl}/isd/SaveDistributeGSTTax`, data);
  }

  downloadAllDistributeGSTTax(gstin, period, documetnType, itcType, selectedInvoices, distributedGSTBranch): Observable<any> {
    const data = new FormData();
    data.append('gstin', gstin);
    data.append('period', period);
    data.append('documetnType', documetnType);
    data.append('itcType', itcType);
    data.append('selectedInvoices', JSON.stringify(selectedInvoices));
    data.append('distributedGSTBranch', JSON.stringify(distributedGSTBranch));
    return this._httpClient.post(`${this.gstrServiceBaseUrl}/isd/DownloadDistributeGSTTax`, data, { responseType: 'blob' });
  }

  downloadDistributeGSTTax(gstin, period): Observable<any> {
    const data = new FormData();
    data.append('gstin', gstin);
    data.append('period', period);
    return this._httpClient.post(`${this.gstrServiceBaseUrl}/isd/DownloadDistributeGSTTax`, data, {
      responseType: 'blob', observe: 'response'
    })
  }

  resetDistribution(gstin, period, documentType, ITC, type): Observable<any> {
    const data = new FormData();
    data.append('gstin', gstin);
    data.append('period', period);
    data.append('documentType', documentType);
    data.append('ITC', ITC);
    data.append('type', type);
    return this._httpClient.post(`${this.gstrServiceBaseUrl}/isd/ResetDistribution`, data);
  }

  downloadExcelDocuments(gstin, period, documetnType, itcType): Observable<any> {
    const data = new FormData();
    data.append('gstin', gstin);
    data.append('period', period);
    data.append('documetnType', documetnType);
    data.append('itcType', itcType);
    return this._httpClient.post(`${this.gstrServiceBaseUrl}/isd/DownloadExcelDocuments`, data, {
        responseType: 'blob', observe: 'response'
    })
  }

  generateISDInvoices(gstin, period, itc): Observable<any> {
    return this._httpClient.get(`${this.gstrServiceBaseUrl}/isd/GenerateISDInvoices?gstin=${gstin}&period=${period}&itc=${itc}`);
  }

  getUploadedFile(completeFileName): Observable<any> {
    const data = new FormData();
    data.append('FileName', completeFileName);
    return this._httpClient.post(`${this.gstrServiceBaseUrl}/common/DownloadReport`, data, {
        responseType: 'blob', observe: 'response'
    })
  }

  downloadExcelUploadReport(stegingExecutionID): Observable<any> {
    const data = new FormData();
    data.append('stagingExecutionID', stegingExecutionID);
    return this._httpClient.post(`${this.gstrServiceBaseUrl}/isd/DownloadExcelUploadReport`, data, {
     responseType: 'blob', observe: 'response'
    })
  }
  getGstInAndReportPeriodBasedOnRetunType(returnType: string): Observable<any> {
    return this._httpClient.get(`${this.gstrServiceBaseUrl}/isd/GetISDDropdownList?returnType=${returnType}`);
  }
  saveReportRequestForGSTR6(reportRequest: CashItcBalance): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/isd/InsertReportRequestForGSTR6', reportRequest
    );
  }
  getReportRequestForGSTR6(currentPage: number, pageSize: number, reportType: string): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/isd/GetReportRequestForGSTR6?currPage=' + currentPage + '&pgSize=' + pageSize + '&reportType=' + reportType
    );

  }
  updateISDNumbers(isdGSTIN: any[], isdPeriod: any[],isdDocumentNumber: any[],isdDistributionGSTIN: any[],ITC:string): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/isd/UpdateISDNumbers?ISDGSTIN=' + isdGSTIN + '&ISDDocumentNumber=' + isdDocumentNumber +'&Period=' + isdPeriod + '&ISDDistributionGSTIN=' + isdDistributionGSTIN + '&ITC=' + ITC,
      {
      
      },

    );
  }
  generateISDPDFInvoice(isdGSTIN: any[],isdDocumentNumber: any[],isdDocumentDate: any[],ITC:string): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/isd/GenerateISDPDFInvoice?ISDGSTIN=' + isdGSTIN + '&ISDDocumentNumber=' + isdDocumentNumber + '&ISDDocumentDate=' + isdDocumentDate + '&ITC=' + ITC,
      {
      },

    );
  }
}
