import { Component, OnInit, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetEInvoiceService } from '../../../shared/services/get/get-srv-eInv/get-eInvoice.service';
import { GetSrvSharedService } from '../../../shared/services/get/get-srv-global/get-srv-shared.service';
import { GlobalSrvConstantsService } from '../../../shared/services/global/global-constants/global-srv-constants.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-sub-cnl-req-detail',
    templateUrl: './sub-cnl-req-detail.component.html',
    styleUrls: ['./sub-cnl-req-detail.component.css']
})
export class SubCnlReqDetailComponent implements OnInit {

    requestID: any;
    currentPage: any;
    pageSize: any;

    dataList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    columnList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    dataListPageCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    dataListSelection: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    isTableMultiSelect: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    constructor(
        private snackBar: MatSnackBar,
        private getEInvoiceService: GetEInvoiceService,
        private getColumnList: GetSrvSharedService,
        @Inject(MAT_DIALOG_DATA) data) {
        this.requestID = data.title;
    }

    ngOnInit() {
        this.getDisplayedColumnList('IRN_CNLSNAPDETAILS');
    }

    getDisplayedColumnList(tableType: string) {
        this.getColumnList.getColumnList(tableType).subscribe
            (
                data => {
                    if ('200'.localeCompare(data.status) === 0) {
                        this.columnList.next(data.data);
                    }
                },
                error => {
                    let errorStatus;
                    let errorMessage;
                    if (error.error.message !== undefined) {
                        errorStatus = error.error.status;
                        errorMessage = error.error.message.substring(0, 90);
                    } else {
                        errorStatus = error.status;
                        errorMessage = error.error;
                    }
                    this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                        duration: GlobalSrvConstantsService.SNACKBARDURATION,
                        panelClass: ['danger-snackbar']
                    });
                }
            );
    }

    getRequestDetail(currentPage, pageSize) {
        this.getEInvoiceService.getEICancelRequestDetails(this.requestID, pageSize, currentPage).subscribe(
            data => {
                if ('200'.localeCompare(data.status) === 0) {
                    if (data.data && data.data.dataList && data.data.dataList.length > 0) {
                        for (let i = 0; i < data.data.dataList.length; i++) {
                            if (data.data.dataList[i].statusDescription === "IRN Cancelled") {
                                data.data.dataList[i].message = "Success";
                            }
                        }
                    }

                    this.dataList.next(data.data.dataList);
                    this.dataListPageCount.next(data.data.count);
                }
            },
            error => {
                let errorStatus;
                let errorMessage;
                if (error.error.message !== undefined) {
                    errorStatus = error.error.status;
                    errorMessage = error.error.message.substring(0, 90);
                } else {
                    errorStatus = error.status;
                    errorMessage = error.error;
                }
                this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            }
        );
    }

    receiveEIcnlInvoicePageEvent(event: any) {
        this.currentPage = event.currentPage;
        this.pageSize = event.pageSize;
        this.getRequestDetail(this.currentPage, this.pageSize);
    }

    receiveDataSelection(event: any) {
    }

}
