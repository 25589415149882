import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalApiService } from '../../global/global-api/global-api.service';
import { EwayBillManualGenerationRequestDetails } from '../../../models/get/get-md-global/boew-inv/get-boew-inv-search';

@Injectable({
    providedIn: 'root'
})
export class PostInvoiceSearchService {

    baseUrl = GlobalApiService.GLOBALAPIURL;
    oganizationDetail = GlobalApiService.ORGANIZATIONDATA;
    constructor(private _httpClient: HttpClient) { }

    postInvoiceFilterData(InvoiceFilters: any): Observable<any> {
        return this._httpClient.post(
            this.baseUrl + '/bo/documentSearch', InvoiceFilters);
    }

    postInvoiceDeleteData(invHeaderIDs: any): Observable<any> {
        return this._httpClient.request(
            'delete',
            this.baseUrl + '/bo/inv', {
            body: invHeaderIDs
        });
    }

    postGenerateEwayData(invHeaderIDs: any, supplyType : string): Observable<any> {
        const EwayBillRequest: EwayBillManualGenerationRequestDetails = {} as EwayBillManualGenerationRequestDetails;
        EwayBillRequest.invoiceHeaderID = invHeaderIDs;
        EwayBillRequest.supplyType = supplyType;
        return this._httpClient.post(
            this.baseUrl + '/ewaybill/generate/request', 
            EwayBillRequest);
    }

    postInvoiceLineItemData(invoiceHeader: any): Observable<any> {
        return this._httpClient.post(
            this.baseUrl + '/bo/inv/line', invoiceHeader);
    }

    postGenerateInvoiceReport(selectFPandGstin: any): Observable<any> {
        return this._httpClient.post(
            this.baseUrl + '/ew/inv/report', selectFPandGstin
        );
    }
}
