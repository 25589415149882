import { EwayBillCancelOperation } from '../../../shared/models/post/pst-md-boew-eways/pst-boew-rqst-operation';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config/app-config.service';
import { GlobalApiService } from '../global/global-api/global-api.service';
import { EWayDownloadRequest } from '../../../shared/models/global/boew-eway/get-boew-eway-search';

@Injectable({
  providedIn: 'root'
})
export class EwayBillService {
  EwayBillSearchNoneSelected : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  ClearSummaryOnSwitchEwbAction :  BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  constructor(private _httpClient: HttpClient ) { }
  ewayBillAPIBaseUrl = AppConfigService.ApiEnvoiceEwaybillServiceUrl;

  getGstinFPTransporterGstinList(): Observable<any> {
    
    return this._httpClient.get(
      this.ewayBillAPIBaseUrl + '/ewaybill/getfilterdata'
      );
  }

  getSearchedEwayInvoices(ewayInvoiceFilters: any): Observable<any> {
    return this._httpClient.post(this.ewayBillAPIBaseUrl + '/ewaybill/searchinvoices', ewayInvoiceFilters);
  }

  getconsolidatedEwB(ConsolidatedEwayBillID: any): Observable<any> {
    return this._httpClient.get(this.ewayBillAPIBaseUrl + '/ewaybill/getconsolidatedEwB/'+ConsolidatedEwayBillID);
  }

  getCancelReasons(): Observable<any> {
    return this._httpClient.get(this.ewayBillAPIBaseUrl + '/ewaybill/getcancellationreasons'
    );
       }
  getUpdatePartBReasons(): Observable<any> {
    return this._httpClient.get(this.ewayBillAPIBaseUrl + '/ewaybill/getupdatepartbreasons');
  }

  getVehicleType(): Observable<any> {
    return this._httpClient.get(this.ewayBillAPIBaseUrl + '/ewaybill/getvehiclettype');
  }

  getExtendValidityReasons(): Observable<any> {
    return this._httpClient.get(this.ewayBillAPIBaseUrl + '/ewaybill/getextendvalidityreasons');
  }

  postInvoiceLineItemData(invoiceHeader: any): Observable<any> {
    return this._httpClient.post(
      this.ewayBillAPIBaseUrl + '/ewaybill/getinvoicelineitems', invoiceHeader);
}

doEwayBillCancel(cancelRequestData : any) : Observable<any> {
  return this._httpClient.post(this.ewayBillAPIBaseUrl + '/ewaybill/cancel', cancelRequestData);
}

doEwayBillUpdatePartB(updatePartBRequestData : any) : Observable<any> {
  return this._httpClient.post(this.ewayBillAPIBaseUrl + '/ewaybill/updatepartb', updatePartBRequestData);
}
doEwayBillUpdateTransport(updateTransportRequestData : any) : Observable<any> {
  return this._httpClient.post(this.ewayBillAPIBaseUrl + '/ewaybill/updatetransportdetails', updateTransportRequestData);
}
DoEwayBillConsolidate(consolidateRequestData : any) : Observable<any> {
  return this._httpClient.post(this.ewayBillAPIBaseUrl + '/ewaybill/consolidate', consolidateRequestData);
}

postForExtendEwayBill(forExtend: any ): Observable<any> {
  return this._httpClient.post(this.ewayBillAPIBaseUrl + '/ewaybill/getextendvalidityewaybilldetails', forExtend);
}

doEwayBillExtendValidity(extndEway: any ): Observable<any> {
  return this._httpClient.post(this.ewayBillAPIBaseUrl + '/ewaybill/extendvalidity', extndEway);
}

GenerateEwayReport(selectFPandGstin: any): Observable<any> {
  return this._httpClient.post(this.ewayBillAPIBaseUrl + '/ewaybill/generateEwayBillReport', selectFPandGstin,
  {responseType: 'blob'}
  );
}
GenerateConsolidatedEwayReport(selectFPandGstin: any): Observable<any> {
  return this._httpClient.post(this.ewayBillAPIBaseUrl + '/ewaybill/consolidatedEwayBillReport', selectFPandGstin,
  {responseType: 'blob'}
  );
}
getConsolidatedSearchResults(ConsolidatedewayInvoiceFilters: any): Observable<any> {
  return this._httpClient.post(this.ewayBillAPIBaseUrl + '/ewaybill/getconsolidatedsearchdata ', ConsolidatedewayInvoiceFilters);
}
public getEwayInvoices(eway: EWayDownloadRequest) {
  return this._httpClient.post(
      GlobalApiService.GLOBALREPORTURL + '/file/ewaydownload', eway
  )
}
}
