import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';

export const authConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: environment.authURL,

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + '/login',

    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: environment.clientId,

    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: 'openid profile email ewapi',

    // Activate Session Checks:
    sessionChecksEnabled: false,

    sessionCheckIFrameUrl: window.location.origin + '/silent-refresh.html',

    postLogoutRedirectUri: window.location.origin + '/logout',

    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

    showDebugInformation: environment.showDebugInfo,

    requireHttps: environment.requireHttps

  };
