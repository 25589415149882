import { Injectable } from "@angular/core";
import { map, catchError } from 'rxjs/operators';
import {throwError} from 'rxjs';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Location } from '@angular/common'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Observable } from 'rxjs';
import { GlobalApiService } from './services/global/global-api/global-api.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    token: string;
    identity: string;

  constructor(
      private location: Location,
  ) { }

  // function which will be called for all http calls
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.url.toString().includes(environment.authURL))
    {
      if (location.pathname == '/unauthorized') {
        return next.handle(request);
      }
      else {
        return next.handle(request).pipe(tap(() => { },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }
              if (location.pathname != '/unauthorized') {
                window.location.href = 'unauthorized';
              }
              return;
            }
          }));
      }
    }
  //  else if(request.headers.get('Organization') != null)
  //  {
  //   return next.handle(request);
  //  }
  //  else if(GlobalApiService.getCurrentOrganizationID() != '')
  //  {
  //   const newRequest = request.clone({
  //       headers: request.headers.set('Organization', String(GlobalApiService.getCurrentOrganizationID())),
  //     });
  //     return next.handle(newRequest);
  //  }
   else
   {
      if (location.pathname == '/unauthorized') {
        return next.handle(request);
      }
      else {
        return next.handle(request).pipe(tap(() => { },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }
              if (location.pathname != '/unauthorized') {
                window.location.href = 'unauthorized';
              }
              return;
            }
          }));
      }
    }
   
}

}
