import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { GlobalApiService } from './shared/services/global/global-api/global-api.service';
import { Roles } from './shared/Constants';
import { I } from '@angular/cdk/keycodes';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private oauthService: OAuthService, private router: Router, private globalsrv: GlobalApiService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (this.oauthService.hasValidIdToken()) {
            //this.register_tab_GUID();
            let org = JSON.parse(sessionStorage.getItem('id_token_claims_obj')) as any;
            let value = [];
            value.push(org.iss);
            localStorage.setItem("environment", JSON.stringify(value));
            var login = localStorage.getItem("login");
            var orgSwitch = localStorage.getItem("orgSwitch");    
            var env = localStorage.getItem("environment");
            var currentOrg = GlobalApiService.getCurrentOrganizationID();
            if(env.includes(org.iss) && (login == "false" || (currentOrg !='' && orgSwitch !=null && orgSwitch != currentOrg))){
            sessionStorage.clear();
            localStorage.removeItem("orgSwitch");
            this.router.navigate(['/login']);
            return false;
            }
            switch (state.url) {
                case '/upload':
                    return this.checkIsRolesExist(Roles.DataUpload);
                case '/inv':
                    return this.checkIsRolesExist(Roles.DataUpload);
                case '/ei/irn':
                    return this.checkIsRolesExist(Roles.EInvoice);
                case '/ew/eway':
                    return this.checkIsRolesExist(Roles.EWayBill);
                case '/ew/ceway':
                    return this.checkIsRolesExist(Roles.EWayBill);
                case '/anx/one':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/users':
                    return this.checkIsRolesExist(Roles.Admin);
                case '/user':
                    return this.checkIsRolesExist(Roles.Admin);
                case '/masters/Customer':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/masters/Supplier':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/masters/Items':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/masters/POMaster':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/ei/regenerate-irn':
                    return this.checkIsRolesExist(Roles.InternalAdmin);
                case 'dataretrieval/dataretrieval':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/ewaybill/main-eway-bill':
                    return this.checkIsRolesExist(Roles.EWayBill);
                case '/ewaybill/consolidated-eway-bill':
                    return this.checkIsRolesExist(Roles.EWayBill);
                case '/returns/GSTR1':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/returns/GSTR3B':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/returns/GSTR9':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/returns/FileReturns':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/returns/ITC04':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/returns/TrackReturns':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/gstr6/InputServiceDistribution':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/gstr6/Report':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/gstr6/Reconcilation':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/returns/GSTINSessionStatus':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/ledger/CashItcBalance':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/ledger/CashLedgerDetails':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/gstinvalidation/validate':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/admin/organization':
                    return this.checkIsRolesExist(Roles.SuperAdmin);
                case '/admin/pat':
                    return this.checkIsRolesExist(Roles.Admin);
                case '/admin/users':
                    return this.checkIsRolesExist(Roles.Admin);
                case '/upload':
                    return this.checkIsRolesExist(Roles.DataUpload);
                case '/ei/documentSearch':
                    return this.checkIsRolesExist(Roles.DataUpload);
                case '/reports/reports':
                    return this.checkIsRolesExist(Roles.Reports);

                case '/returns/ITCComparision':
                    return this.checkIsRolesExist(Roles.MaximizeITC);
                case '/returns/GSTR2A':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/returns/GSTR8':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/returns/GSTR7':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/dataretrieval/dataretrieval':
                    return this.checkIsRolesExist(Roles.Returns);
                case '/returns/ReconDashboard':
                    return this.checkIsRolesExist(Roles.MaximizeITC);
                case '/returns/ReconReport':
                    return this.checkIsRolesExist(Roles.MaximizeITC);
                case '/returns/JsonToExcel':
                    return this.checkIsRolesExist(Roles.Utilities);

                case '/reports/dashboard':
                    return this.checkIsRolesExist(Roles.AuditReadiness);
                case '/reports/auditReadiness':
                    return this.checkIsRolesExist(Roles.AuditReadiness);
                case '/reports/health':
                    return this.checkIsRolesExist(Roles.VRM);
                case '/reports/vendoritc':
                    return this.checkIsRolesExist(Roles.VRM);
                case '/taxnotice/taxnotice':
                    return this.checkIsRolesExist(Roles.TaxNotice);
                case '/gstrefund/RefundApplications' :
                    return this.checkIsRolesExist(Roles.GSTRefund);


                default:
                    if (state.url.includes('/user?Id')) {
                        return this.checkIsRolesExist(Roles.Admin);
                    }
                    return true;
            }
        }

        this.router.navigate(['/login']);

        return false;
    }
    checkIsRolesExist(roles: string) {
        if (roles != "" && roles != undefined) {

            var rolesRequired = roles.split('!');
            let count = 0;
            rolesRequired.forEach((role) => {
                if (this.globalsrv.userroles.findIndex(x => x == role) != -1) {
                    count = count + 1;
                }
            })
            return rolesRequired.length == count
        }
    }
}
