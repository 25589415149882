import { ReconBase } from "./recon-base";

export class GSTR2ARequestViewModel extends ReconBase{
    G2AStartDate:string;
    G2AEndDate:string;
    PRStartDate:string;
    PREndDate:string;
    G2AReportedPeriod : string;
    PRReportedPeriod : string;
    ToleranceByTaxableAmt:number;
    ToleranceByTaxAmt:number;
}

export class GSTR2AReconRequestViewModel extends ReconBase{
    G2AStartDate:string;
    G2AEndDate:string;
    PRStartDate:string;
    PREndDate:string;
    G2AReportedPeriod : string[];
    PRReportedPeriod : string[];
    ToleranceByTaxableAmt:number;
    ToleranceByTaxAmt:number;
    IgnoreSplChr: boolean;
    RCMSelection:string;
    ITCEligibleSelection:string;
    validateVendorGSTIN:boolean;
    VendorGSTINFileID: number;
    ToleranceByCessAmt: number;
}

export class VendorGSTINViewModel {
    FormData = new FormData();
    VendorCTINs : string[];
}