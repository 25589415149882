import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SrvLoaderService } from '../../shared/services/global/global-shared/global-srv-loader/srv-loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  constructor(private loaderService: SrvLoaderService) { }

  ngOnInit() {
  }

}
