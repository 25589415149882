import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { SubReconcileEmailGridComponent } from 'src/app/returns/gstr2-a/sub-components/sub-reconcile-email-grid/sub-reconcile-email-grid.component';
import { BoConfirmationDialogComponent } from '../bo-confirmation-dialog/bo-confirmation-dialog.component';
import { Dialog, DialogTypes } from '../Constants';

@Component({
  selector: 'app-ag-grid',
  templateUrl: './ag-grid.component.html',
  styleUrls: ['./ag-grid.component.css']
})
export class AgGridComponent implements OnInit {
  @Input() ColumnDefs: any;  
  @Input() RowData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);  
  @Input() IsColumnsToFit: boolean;  
  @Input() isMismatch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);  
  @Input() rowHeightValue: string;  
  @Input() tabName: string;  
  @Output() downloadEvent = new EventEmitter<any>();
  @Output() selectedEvent= new EventEmitter<any>();
  @Input() downloadDone: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);  
  @Input() datafetched: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);  
  @Input() SelectedGSTINs: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  @Input() SelectedFYs: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  @Output() InvokeEmail = new EventEmitter<any>();
  @Input() actionView: BehaviorSubject<any> = new BehaviorSubject<any>(null);  
  gstins:any[]=[];
  gridApi: any;  
  isDataPresent : boolean = true;
  isNotSingleGSTIN:boolean = true;
  @Input() EnableAutoReconEmail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);  
  gridColumnApi: any;
  pagination = true;
  paginationPageSize = 20;
  PageSize =[20,40,60,100];
  domLayout:any;
  rowHeight:any;
  rowSelection:any;
  selectedData:any;
  frameworkComponents: any;
  misMatch : boolean = false;
  isDownloaded: boolean = false;
  dataSet: boolean = false;
  rowDataForGrid:any[]=[];
  lengthOfData : number =0;
  private selectAll: boolean = false;
  selectDataAll : boolean = false;
  totalTaxValue : number = 0;
  totalIGST : number = 0;
  totalCGST : number = 0;
  totalSGST : number = 0;
  totalTaxValue2A : number = 0;
  totalIGST2A : number = 0;
  totalCGST2A : number = 0;
  totalSGST2A : number = 0;
  totalTaxValuePR : number = 0;
  totalIGSTPR : number = 0;
  totalCGSTPR : number = 0;
  totalSGSTPR : number = 0;
  action = '2A Data';
  type = '2A';
  constructor(private dialog: MatDialog) { 
  }
  ngOnInit() {
    this.totalTaxValue = 0;
    this.totalIGST = 0;
    this.totalCGST = 0;
    this.totalSGST = 0;
    this.totalTaxValue2A  = 0;
    this.totalIGST2A  = 0;
    this.totalCGST2A  = 0;
    this.totalSGST2A  = 0;
    this.totalTaxValuePR = 0;
    this.totalIGSTPR = 0;
    this.totalCGSTPR  = 0;
    this.totalSGSTPR  = 0;
    this.domLayout = 'autoHeight';
    this.rowHeight = this.rowHeightValue;
    this.rowSelection = 'multiple';
    this.RowData.subscribe(data => {
      this.rowDataForGrid =data;
      if(this.rowDataForGrid != null){
        this.lengthOfData = (this.rowDataForGrid.length)/2;
      }
    })
    this.RowData.subscribe(data => {
      this.rowDataForGrid =data;
      if(this.rowDataForGrid != null){
        this.totalTaxValue = 0;
        this.totalIGST = 0;
        this.totalCGST = 0;
        this.totalSGST = 0;
        this.totalTaxValue2A  = 0;
        this.totalIGST2A  = 0;
        this.totalCGST2A  = 0;
        this.totalSGST2A  = 0;
        this.totalTaxValuePR = 0;
        this.totalIGSTPR = 0;
        this.totalCGSTPR  = 0;
        this.totalSGSTPR  = 0;
        this.lengthOfData = (this.rowDataForGrid.length)/2;
        this.rowDataForGrid.forEach(element => {
          if(this.misMatch){
            if(element.type == 'PR'){
              if(element.docType == 'CDN'){
                this.totalTaxValuePR = this.totalTaxValuePR - element.taxableValue;           
                this.totalCGSTPR = this.totalCGSTPR - element.cgst;
                this.totalSGSTPR = this.totalSGSTPR - element.sgst;
                this.totalIGSTPR = this.totalIGSTPR - element.igst; 
              }    
              else{
                this.totalTaxValuePR = this.totalTaxValuePR + element.taxableValue;           
                this.totalCGSTPR = this.totalCGSTPR + element.cgst;
                this.totalSGSTPR = this.totalSGSTPR + element.sgst;
                this.totalIGSTPR = this.totalIGSTPR + element.igst; 
              }         
            }
            else if(element.type == '2A' || element.type == '2B' || element.type =="IMS"){
              if(element.docType == 'CDN'){
              this.totalCGST2A = this.totalCGST2A - element.cgst;
              this.totalSGST2A = this.totalSGST2A - element.sgst;
              this.totalIGST2A = this.totalIGST2A - element.igst;
              this.totalTaxValue2A = this.totalTaxValue2A - element.taxableValue;
              }
              else{
                this.totalCGST2A = this.totalCGST2A + element.cgst;
                this.totalSGST2A = this.totalSGST2A + element.sgst;
                this.totalIGST2A = this.totalIGST2A + element.igst;
                this.totalTaxValue2A = this.totalTaxValue2A + element.taxableValue;
              }
            }
          }
          else{
            if(element.docType == 'CDN'){
            this.totalTaxValue = this.totalTaxValue - element.taxableValue;           
            this.totalCGST = this.totalCGST - element.cgst;
            this.totalSGST = this.totalSGST - element.sgst;
            this.totalIGST = this.totalIGST - element.igst;   
            }  
            else{
              this.totalTaxValue = this.totalTaxValue + element.taxableValue;           
              this.totalCGST = this.totalCGST + element.cgst;
              this.totalSGST = this.totalSGST + element.sgst;
              this.totalIGST = this.totalIGST + element.igst;   
            }    
          }
        });
      }
    })
    this.EnableAutoReconEmail.subscribe(r => {
      this.isNotSingleGSTIN = r;  
    });
    this.isMismatch.subscribe(data =>{
      this.misMatch = data;
    })
    this.downloadDone.subscribe(data =>{
      this.isDownloaded = data;
    })
    this.datafetched.subscribe(data =>{
      this.dataSet = data;
    })
    this.SelectedGSTINs.subscribe(data =>{
      this.gstins = data;
    })
    this.actionView.subscribe(data =>{
      this.action = (data == "GSTR2B")? "2B Data" : (data == "GSTR2A")? "2A Data" : "IMS Data";
      this.type = (data == "GSTR2B")? "2B" : (data == "GSTR2A")? "2A" : "IMS";
    })
  }

  getRowStyle(params) {
    if(params.data.hasOwnProperty("id")){
      if (params.data.id == 1) {
        if(params.data.type == "2A" || params.data.type == "IMS" || params.data.type == "2B" || params.data.type == "OnlyIn2A" || params.data.type == "OnlyIn2B" || params.data.type == "OnlyInIMS"){
          return { 'background-color': '#656565' , 'color':'white'}
        }
        else{
          return { 'background-color': '#656565' , 'color':'#8bc34a','border': 'solid 0px'}
        }
    }
    else if(params.data.id == 0){
      return { 'background-color': '#424242' , 'color':'white'}
    }
     else  {
       if(params.data.type == "2A" || params.data.type == "IMS" || params.data.type == "2B" || params.data.type == "OnlyIn2A" || params.data.type == "OnlyIn2B" || params.data.type == "OnlyInIMS"){
        return { 'background-color': '#424242' , 'color':'white'}
       }
       else{
        return { 'background-color': '#424242' , 'color':'#8bc34a','border': 'solid 0px'}
       }
      
  }
    }   
    else{
      return { 'background-color': '#424242' };
    }
    
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowDataForGrid);  
    if (this.IsColumnsToFit) {  
      this.gridApi.sizeColumnsToFit();  
    }  
  }

  PageSizeChanged(val){
    this.gridApi.refreshHeader();
    this.paginationPageSize = val;
    this.gridApi.paginationSetPageSize(Number(this.paginationPageSize));  
  }

  isRowSelectable(params){
    if(params.data != null){
        if(params.data.hasOwnProperty("type")){
          if(params.data.type =="PR" || params.data.type =="none" || 
          params.data.type == "OnlyInPR" || params.data.type == "OnlyIn2A" ||
          params.data.type == "OnlyIn2B" ||
          params.data.type == "OnlyInIMS" ){
            return true;
          }
        else{
          return false;
        }
      }
      else{
        return false;
      }     
    }
    return false;
  }

  onRowSelected(event) {
    this.selectedData = this.gridApi.getSelectedRows();
    this.isDataPresent = this.selectedData.length > 0 ? false : true;
  }
  onSelectionChanged() {
    this.selectedData =[];
    let selectedNodes = this.gridApi.selectionController.selectedNodes;
     Object.keys(selectedNodes).map(index => {
       if(selectedNodes[index] != undefined){        
        this.selectedData.push(selectedNodes[index].data)
       }
     });
     
    this.isDataPresent = this.selectedData.length > 0 ? false : true;
  }

getReconDetails(){
  this.downloadEvent.emit(true);
}

AcceptRows(){
  let data={
    eventType:1,
    dataList:this.selectedData,
    eventAction:this.tabName
  }
  //this.isDataPresent = true;
  this.selectedEvent.emit(data);
}
delinkRows(){
  let data={
    eventType:2,
    dataList:this.selectedData
  }
 // this.isDataPresent = true;
  this.selectedEvent.emit(data);
}

  SelectAllRows(){
    this.selectDataAll = !this.selectDataAll;
    if(this.selectDataAll){
      this.gridApi.selectAllFiltered();
    }
    else{
      this.gridApi.deselectAllFiltered();
    }
  }

  ResetAllRows(){
    this.gridApi.deselectAllFiltered();
  }

  loadForceMatchPopUp() {
    let flag = "2A";
    if (this.action == "2B Data")
      flag = "2B";
    else if (this.action == "IMS Data")
      flag = "IMS";
    let dialogTemplate = new Dialog();
    dialogTemplate.Type = DialogTypes.AutoReconUpload;
    dialogTemplate.ReportType = "Auto GSTR" + flag + " Recon";
    let element = {
      requestID:0,
      gstin:"",
      reportName:"AutoRecon",
      type: (flag == "2B") ? "GSTR2B" : (flag == "2A") ? "GSTR2A" :"IMS",
      tabName:this.tabName == "NotInPR" || this.tabName == "NotIn2A"  || this.tabName == "NotIn2B" || this.tabName == "NotInIMS" ? null : this.tabName 
    }
    dialogTemplate.AdditionalInfo = element;
    dialogTemplate.Message = "Perform ForceMatch on PR & " + flag + " documents.";
    const dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
        data: dialogTemplate
    });
  }

  emailPopUp() {
    this.InvokeEmail.emit();
  }

  openMailLogsDialog(reportType) {
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.dialog.open(SubReconcileEmailGridComponent, {
        data: { 
            _reportType: reportType, 
            _action:(this.action == "2B Data")? "GSTR2B" : (this.action == "2A Data")? "GSTR2A" :"IMS"
        },
    });
    dialogRef.updateSize('70%', '70%');
    dialogRef.afterClosed().subscribe(result => {
    });
}

ActionTaken(ev){
  let data={
    eventType:ev,
    dataList:this.selectedData,
    eventAction:this.tabName
  }
  //this.isDataPresent = true;
  this.selectedEvent.emit(data);
}
};
