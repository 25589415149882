import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bo-log-out',
  templateUrl: './bo-log-out.component.html',
  styleUrls: ['./bo-log-out.component.css']
})
export class BoLogOutComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
  }

  login()
  {
      this.document.location.href = environment.authURL+ 'Account/Login';
  }

}
