import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../common/app-config/app-config.service';
import { GlobalApiService } from '../global/global-api/global-api.service';
import { GetGSTR2AReportViewModel, GetGSTR2ARequetViewModel, QrcInvoiceDownloadModel, QrcInvoiceReportGenerationModel } from 'src/app/shared/models/global/boew-eway/get-boew-eway-search';

@Injectable({
  providedIn: 'root'
})
export class QrcodeService {

  apiGstrServiceUrl = AppConfigService.ApiGstrServiceUrl;
  constructor(private _httpClient:HttpClient) { }

  UploadEinvoice(formdata:any): Observable<any> {
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/QrCode/UploadQrCodeFile',formdata);    
  }
  UploadMultiEinvoice(formdata:any): Observable<any> {
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/QrCode/MultiUploadQrCodeFile',formdata);    
  }

  getQrcUploadResponseDetails(GetGSTR2ARequetViewModel: GetGSTR2ARequetViewModel): Observable<any> {
    console.log(GetGSTR2ARequetViewModel);
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/QrCode/GetUploadQrCodeFileStatusGrid', GetGSTR2ARequetViewModel
    )
  }
  getQrcViewResponseDetails(GetGSTR2ARequetViewModel: GetGSTR2ARequetViewModel): Observable<any> {
    console.log(GetGSTR2ARequetViewModel);
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/QrCode/GetViewQrCodeFileStatusGrid', GetGSTR2ARequetViewModel
    )
  }
  getQrcInvoiceDownlaod(invoiceModel: QrcInvoiceDownloadModel): Observable<any> {
  
    console.log(GetGSTR2ARequetViewModel);
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/QrCode/QRCDownloadInvoices', invoiceModel,{
      responseType: 'blob'
    }
    )
  }
  getQrcGenerateReport(invoiceModel: QrcInvoiceReportGenerationModel): Observable<any> {
  
    console.log(GetGSTR2ARequetViewModel);
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/QrCode/QRCGenerateInvoicesReport', invoiceModel,{
         responseType: 'blob'
    }
    )
  }
  getQRCIsViveEnabled(): Observable<any> {
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/QrCode/QRCIsViveEnabled');
  }
  downloadDefaultFormatXlsx(): Observable<any> {
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/QrCode/DownloadIsSellerEinvEligibleDefaultFormat', null,{
         responseType: 'blob'
    }
    )
   }
   UploadIsSellerEinvEligible(formdata:any): Observable<any> {
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/QrCode/IsSellerEinvEligibleUpload',formdata);    
  }
  getISSEUploadResponseDetails(GetGSTR2ARequetViewModel: GetGSTR2ARequetViewModel): Observable<any> {
    console.log(GetGSTR2ARequetViewModel);
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/QrCode/GetIsSellerEinvEligibleUploadStatusGrid', GetGSTR2ARequetViewModel
    )
  }

  GetVendorGstinFiles_ByRequestsID(id:number): Observable<any> {
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/QrCode/GetVendorGstinFilesByRequestsID/'+ id);
    }

}
