import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerMasterDataModel, ItemMasterDataModel, MastersDataModel, POMasterDataModel, SupplierMasterDataModel } from '../../models/global/boew-eway/get-boew-eway-search';
import { AppConfigService } from '../common/app-config/app-config.service';
import { GlobalApiService } from '../global/global-api/global-api.service';

@Injectable({
    providedIn: 'root'
})
export class MasterService {
    MastersDataModel: MastersDataModel = {} as MastersDataModel;
    MasterDeleteStatus: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    apiGstrServiceUrl = AppConfigService.ApiGstrServiceUrl;
    constructor(private _httpClient: HttpClient) { }

    UploadCustomerDataFile(formdata: any): Observable<any> {
        return this._httpClient.post(
            this.apiGstrServiceUrl + '/Masters/UploadCustomerDataFile', formdata);
    }
    UploadSupplierDataFile(formdata: any): Observable<any> {
        return this._httpClient.post(
            this.apiGstrServiceUrl + '/Masters/UploadSupplierDataFile', formdata);
    }
    UploadItemDataFile(formdata: any): Observable<any> {
        return this._httpClient.post(
            this.apiGstrServiceUrl + '/Masters/UploaditemDataFile', formdata);
    }
    UploadPODataFile(formdata: any): Observable<any> {
        return this._httpClient.post(
            this.apiGstrServiceUrl + '/Masters/UploadPODataFile', formdata);
    }
    getGstinSessionDetails(GetGSTR2ARequetViewModel: MastersDataModel): Observable<any> {
        console.log(GetGSTR2ARequetViewModel);
        return this._httpClient.post(
            this.apiGstrServiceUrl + '/Masters/GetGridDataForAllMasters', GetGSTR2ARequetViewModel
        )
    }

    InsertCustomerDetails(CustomerMasterDataModel: CustomerMasterDataModel): Observable<any> {
        console.log(CustomerMasterDataModel);
        return this._httpClient.post(
            this.apiGstrServiceUrl + '/Masters/InsertIntoCustomerMaster', CustomerMasterDataModel
        )
    }
    InsertItemDetails(ItemMasterDataModel: ItemMasterDataModel): Observable<any> {
        console.log(ItemMasterDataModel);
        return this._httpClient.post(
            this.apiGstrServiceUrl + '/Masters/InsertIntoItemsMaster', ItemMasterDataModel
        )
    }
    InsertSupplierDetails(SupplierMasterDataModel: SupplierMasterDataModel): Observable<any> {
        console.log(SupplierMasterDataModel);
        return this._httpClient.post(
            this.apiGstrServiceUrl + '/Masters/InsertIntoSupplierMaster', SupplierMasterDataModel
        )
    }

    InsertPODetails(PODataModel: POMasterDataModel): Observable<any> {
        console.log(PODataModel);
        return this._httpClient.post(
            this.apiGstrServiceUrl + '/Masters/InsertIntoMasterPO', PODataModel
        )
    }

    download(type: string): Observable<any> {
        console.log(type);
        return this._httpClient.get(
            this.apiGstrServiceUrl + '/Masters/DownloadMastersTemplate?type=' + type, { responseType: 'blob' }
        );
    }
    downloadMasterData(type: string): Observable<any> {
        console.log(type);
        return this._httpClient.get(
            this.apiGstrServiceUrl + '/Masters/GetfileIDMaster?MasterType=' + type
        );
    }
    DeleteMasterRecord(ItemCode: string, MasterType: string): Observable<any> {
        console.log(MasterType);

        
        return this._httpClient.get(
            this.apiGstrServiceUrl + '/Masters/DeleteMaster?Code=' + ItemCode + '&MasterType=' + MasterType
        );
    }

    EditMasterRecord(ItemCode: string, MasterType: string): Observable<any> {
        console.log(MasterType);

        
        return this._httpClient.get(
            this.apiGstrServiceUrl + '/Masters/EditMaster?Code=' + ItemCode + '&MasterType=' + MasterType
        );
    }
    GetCacheRefreshStatus(tableName: string): Observable<any> {
        return this._httpClient.get(
            this.apiGstrServiceUrl + '/Masters/GetStatusOfCacheRefresh?TableName=' + tableName
        );

    }

}
