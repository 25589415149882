import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable,TimeoutError } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { InjectionToken, Injectable, Inject } from '@angular/core';
import { HttpHandler, HttpRequest, HttpInterceptor, HttpEvent } from '@angular/common/http';


@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var urlSplit = req.url.split('/');
    if (urlSplit[urlSplit.length - 1] == 'GetITCAutoReconDetails' || urlSplit[urlSplit.length - 1] == 'DownloadITCAutoReconDetails' ||
      urlSplit[urlSplit.length - 1] == 'generateEwayBillReport') {
      return next.handle(req).pipe(timeout(4000000));
    }
    else{
      return next.handle(req).pipe(timeout(4000000));
    }
  }
}
