import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-reconcile-email-grid',
  templateUrl: './sub-reconcile-email-grid.component.html',
  styleUrls: ['./sub-reconcile-email-grid.component.css']
})
export class SubReconcileEmailGridComponent implements OnInit {
    constructor(){}
    ngOnInit(){

    }
}
