import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config/app-config.service';

export enum uploadDocumetAPIEndPoint{
  GetUploadDocumentTemplates = '/bo/getuploaddocumenttemplates',
  GetForceMatchDocumentTemplates = '/GSTR2A/getforcematchdocumenttemplates',
  GetUploadDocumentTemplatesAPOB= '/APOB/getuploaddocumenttemplatesAPOB',
}

@Injectable({
  providedIn: 'root'
})

export class UploadDocumentService {

  constructor(private httpClient: HttpClient) { }
  eInvoiceEwayBillServiceBaseUrl = AppConfigService.ApiEnvoiceEwaybillServiceUrl;
  gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;
  public getUploadDocumentTemplates(){
    return this.httpClient.get(this.eInvoiceEwayBillServiceBaseUrl + uploadDocumetAPIEndPoint.GetUploadDocumentTemplates ,
       {responseType: 'arraybuffer'});
  }
  public getUploadDocumentTemplatesAPOB(){
    debugger
    return this.httpClient.get(this.eInvoiceEwayBillServiceBaseUrl + uploadDocumetAPIEndPoint.GetUploadDocumentTemplatesAPOB ,
       {responseType: 'arraybuffer'});
  }
  public getForceMatchDocumentTemplates(actionType,reportType,tabName,requestType){
    return this.httpClient.get(this.gstrServiceBaseUrl + uploadDocumetAPIEndPoint.GetForceMatchDocumentTemplates + '?actionType=' +actionType + '&reportType='+reportType+ '&tabName='+tabName + '&requestType=' + requestType,
       {responseType: 'arraybuffer'});
  }
}
