import { I } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { getCurrentDateTimeForFileName } from '../helpers/date-time-helper';
import { GlobalSrvConstantsService } from '../services/global/global-constants/global-srv-constants.service';
import { GSTR3bService } from '../services/Returns/gstr3b.service';

@Component({
  selector: 'app-global-ag-grid',
  templateUrl: './global-ag-grid.component.html',
  styleUrls: ['./global-ag-grid.component.css']
})
export class GlobalAgGridComponent implements OnInit {

  @Input() ColumnDefs: any;  
  @Input() RowData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);   
  // @Input() actionView: BehaviorSubject<any> = new BehaviorSubject<any>(null);  
  gridApi: any;  
  isDataPresent : boolean = true;
  gridColumnApi: any;
  pagination = true;
  paginationPageSize = 20;
  PageSize =[20,40,60,100];
  domLayout:any;
  rowHeight:any;
  rowSelection:any;
  selectedData:any;
  frameworkComponents: any;
  isDownloaded: boolean = false;
  dataSet: boolean = false;
  rowDataForGrid:any[]=[];
  rowHeightValue = 50;
  IsColumnsToFit : boolean = true;
  private selectAll: boolean = false;
  selectDataAll : boolean = false;
  action = '2A Data';
  type = '2A';
  value ='percentage';
  columnDefs : any;
  constructor(private dialog: MatDialog,private snackBar: MatSnackBar,private gstr3bService: GSTR3bService) { 
  }
  ngOnInit() {
    this.domLayout = 'autoHeight';
    this.rowDataForGrid =[{pR_vendorGSTIN:'gsdfshd',vendorName:'sgdfsgd',gSTR1FilingStatus:'Y',gSTR3BFilingStatus:'Y',status:'exact',prTotalTax:1000,g2ATotalTax:1000,prTotalTaxPercentage:10,g2ATotalTaxPercentage:9},
    {pR_vendorGSTIN:'gsdfshd',vendorName:'sgdfsgd',gSTR1FilingStatus:'Y',gSTR3BFilingStatus:'Y',prTotalTax:1000,g2ATotalTax:1000,prTotalTaxPercentage:10,g2ATotalTaxPercentage:9}]
    this.rowHeight = this.rowHeightValue;
    this.columnDefs = this.columnDefs
    this.RowData.subscribe(data => {
      this.rowDataForGrid =data;
    })
  }

  getRowStyle(params) {
      return { 'background-color': '#424242' }; 
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowDataForGrid);  
    if (this.IsColumnsToFit) {  
      this.gridApi.sizeColumnsToFit();  
    }  
  }

  PageSizeChanged(val){
    this.gridApi.refreshHeader();
    this.paginationPageSize = val;
    this.gridApi.paginationSetPageSize(Number(this.paginationPageSize));  
  }

  onBtExport() {
        let items: Array<any> = [];
    this.gridApi.forEachNodeAfterFilter(function(node) { 
      items.push(node.data);
  });
  if(items.length > 0){
    let data = {items:items,type:this.value};
    this.gstr3bService.DownloadVendorReport(data).subscribe((data)=>{
      const blob = new Blob([data], {type : data.type});
                    const url = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    let currentdate = new Date();
                    anchor.download = `VendorReport_${getCurrentDateTimeForFileName()}.xlsx`;
                    anchor.href = url;
                    anchor.click();
    },(err)=>{
      this.snackBar.open('Their is no data or Error occured while downloading the document. Please try again', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['danger-snackbar']
    });
    })
  }
  }
  onBtExcludeAthleteColumn() {
    this.value = "Value";
    const filterOptions = [
      'empty',
      {
          displayKey: 'blanks',
          displayName: 'Blanks',
          test: function (filterValue, cellValue) {
              return cellValue == undefined || cellValue == null || cellValue == '' ;
          },
          hideFilterInput: true,
      },
      {
        displayKey: 'notBlanks',
        displayName: 'Not Blanks',
        test: function (filterValue, cellValue) {
            return cellValue != undefined && cellValue != null && cellValue != '' ;
        },
        hideFilterInput: true,
      },
      'contains',
      'notContains',
      'equals',
      'notEqual',
      'startsWith',
      'endsWith'
  ];
  this.ColumnDefs = [   
    { headerName: 'Vendor GSTIN', field: 'pR_VendorGSTIN',  filter: true, sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true,width: 130, suppressSizeToFit: true ,cellClass: 'noborder'},  
  { headerName: 'Vendor Name', field: 'vendorName',  filter: true ,sortable: true,filterParams: {
    filterOptions: filterOptions
  },resizable: true,width: 120, suppressSizeToFit: true} ,
    { headerName: 'GSTR-1 Filing Status', field: 'gstR1FilingStatus',  filter: true,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true,width: 120, suppressSizeToFit: true ,cellClass: 'noborder'},
    { headerName: 'Exact Match', field: 'exactTotalTax', filter: true,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true, width: 120, suppressSizeToFit: true,cellClass: 'noborder'},  
    { headerName: 'Mismatch', field: 'misMatchValue',  filter: true ,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true,width: 120, suppressSizeToFit: true,cellClass: 'noborder'}, 
     { headerName: 'Partial Match', field: 'parValue',  filter: true ,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true,width: 120, suppressSizeToFit: true,cellClass: 'noborder'}, 
      
    { headerName: 'Probable Match', field: 'probValue', filter: true,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true, width: 120, suppressSizeToFit: true,cellClass: 'noborder'}  ,
  { headerName: 'Force Match', field: 'forceTotalTax',  filter: true ,sortable: true,filterParams: {
    filterOptions: filterOptions
},resizable: true,width: 120, suppressSizeToFit: true,cellClass: 'noborder'}, 
{ headerName: 'Cross GSTIN Match', field: 'crossValue',  filter: true ,sortable: true,filterParams: {
  filterOptions: filterOptions
},resizable: true,width: 120, suppressSizeToFit: true,cellClass: 'noborder'}, 
    { headerName: 'Missing in 2A/2B', field: 'InPRValue', filter: true ,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true,width: 120, suppressSizeToFit: true},  
    { headerName: 'Missing in PR', field: 'InG2AValue', filter: true,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true,width: 120, suppressSizeToFit: true,cellStyle: params => {
    if(params.data != null && params.data != undefined){
      return { 'background-color': '#656565'}
      }
    return null}},
  ]; 
  }
    
  // adds the athlete column
  onToggleChange(enable: boolean){
   if(enable){
    
    this.onBtExcludeAthleteColumn();
   }
   else{
    this.onBtIncludeAthleteColumn();
   }
  }
  onBtIncludeAthleteColumn() {
    this.value = "percentage";
    const filterOptions = [
      'empty',
      {
          displayKey: 'blanks',
          displayName: 'Blanks',
          test: function (filterValue, cellValue) {
              return cellValue == undefined || cellValue == null || cellValue == '' ;
          },
          hideFilterInput: true,
      },
      {
        displayKey: 'notBlanks',
        displayName: 'Not Blanks',
        test: function (filterValue, cellValue) {
            return cellValue != undefined && cellValue != null && cellValue != '' ;
        },
        hideFilterInput: true,
      },
      'contains',
      'notContains',
      'equals',
      'notEqual',
      'startsWith',
      'endsWith'
  ];
  this.ColumnDefs = [   
    { headerName: 'Vendor GSTIN', field: 'pR_VendorGSTIN',  filter: true,sortable: true, filterParams: {
      filterOptions: filterOptions
  },resizable: true,width: 130, suppressSizeToFit: true ,cellClass: 'noborder'},  
  { headerName: 'Vendor Name', field: 'vendorName',  filter: true ,sortable: true,filterParams: {
    filterOptions: filterOptions
  },resizable: true,width: 120, suppressSizeToFit: true} ,
    { headerName: 'GSTR-1 Filing Status', field: 'gstR1FilingStatus',  filter: true,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true,width: 120, suppressSizeToFit: true ,cellClass: 'noborder'},  
    { headerName: 'Exact Match', field: 'exactPercentage', filter: true,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true, width: 120, suppressSizeToFit: true,cellClass: 'noborder'},  
    { headerName: 'Mismatch', field: 'misMatchPercentage',  filter: true ,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true,width: 120, suppressSizeToFit: true,cellClass: 'noborder'}, 
     { headerName: 'Partial Match', field: 'parPercentage',  filter: true ,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true,width: 120, suppressSizeToFit: true,cellClass: 'noborder'}, 
      
    { headerName: 'Probable Match', field: 'proPercentage', filter: true,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true, width: 120, suppressSizeToFit: true,cellClass: 'noborder'}  ,
  { headerName: 'Force Match', field: 'forcePercentage',  filter: true ,sortable: true,filterParams: {
    filterOptions: filterOptions
},resizable: true,width: 120, suppressSizeToFit: true,cellClass: 'noborder'}, 
{ headerName: 'Cross GSTIN Match', field: 'crossPercentage',  filter: true ,sortable: true,filterParams: {
  filterOptions: filterOptions
},resizable: true,width: 120, suppressSizeToFit: true,cellClass: 'noborder'}, 
    { headerName: 'Missing in 2A/2B', field: 'InPRPercentage', filter: true ,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true,width: 120, suppressSizeToFit: true},  
    { headerName: 'Missing in PR', field: 'InG2APercentage', filter: true,sortable: true,filterParams: {
      filterOptions: filterOptions
  },resizable: true,width: 120, suppressSizeToFit: true,cellStyle: params => {
    if(params.data != null && params.data != undefined){
      return { 'background-color': '#656565' }
      }
    return null}},
  ]; 
  }
}
