import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalSrvConstantsService {
    constructor() { }
    public static SNACKBARDURATION = 10000;
    public static EWAYGENERATED = 11;
    public static EWAYPENDING = 12;
    public static EWAYERROR = 13;
    public static FULLACCESS = 'fullAccess';
}
