import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { InvoiceItemList } from '../../../../shared/models/get/get-md-boew-inv/get-line-items';
import { GlobalSrvConstantsService } from '../../../../shared/services/global/global-constants/global-srv-constants.service';
import { PostInvoiceSearchService } from '../../../../shared/services/post/pst-srv-boew-inv/post-invoice-search.service';
import { EwayBillService } from '../../../../shared/services/ewayBillService/eway-bill.service';

@Component({
  selector: 'app-sub-ewbill-item-dialog',
  templateUrl: './sub-ewbill-item-dialog.component.html',
  styleUrls: ['./sub-ewbill-item-dialog.component.css']
})
export class SubEwbillItemDialogComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'Hsn',
   // 'Product',
    //'Quantity',
    'Taxablevalue',
    'Cgstrate',
    'Cgstamount',
    'Sgstrate',
    'Sgstamount',
    'Igstrate',
    'Igstamount',
    'Cessrate',
    'Cessamount',
    //'CessnonadvolRate',
    //'CessnonadvolAmt',
    //'otherCharges',
    'TotalLineItemValue'
  ];
  displayInvLineItemList: InvoiceItemList[];
  headerID: string;
  documentNo : string;
  documentDate : string;
  entityGstin : string;
  documentType : string;
  supplyType : string;
  actionCode : string;
  // dataSource = new MatTableDataSource<FileHistory>(this.displayFileHistory);
  invoiceItemList: BehaviorSubject<InvoiceItemList[]> = new BehaviorSubject<InvoiceItemList[]>(null);
  dataSource = new MatTableDataSource<InvoiceItemList>(null);

  constructor(
    private dialogRef: MatDialogRef<SubEwbillItemDialogComponent>,
    public postInvoice: PostInvoiceSearchService,
    private snackBar: MatSnackBar,
    private ewayBillService :EwayBillService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.headerID = data.title;
    this.documentNo = data.documentNo;
    this.documentDate = data.documentDate;
    this.entityGstin = data.entityGstin;
    this.documentType = data.documentType;
    this.supplyType = data.supplytype;
    this.actionCode = data.actionCode;
  }

  // constructor() { }
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {
    this.dataSource.data = [] as InvoiceItemList[];
    this.invoiceItemList.subscribe((data: InvoiceItemList[]) => {
      if (data === undefined) {
        return;
      }
    });
    this.getLineItemData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  goTo(p: number) { this.paginator.pageIndex = p; }

  getLineItemData() {
    const formData = new FormData();
    formData.append('InvoiceHeaderID', this.headerID);
    formData.append('Supplytype', this.supplyType);
    //Depending of actionCode SP get LineItmes will be changed in backend 
    formData.append('actionCode', this.actionCode);

    this.ewayBillService.postInvoiceLineItemData(formData)
    .subscribe
    (
      data => {
        if ('200'.localeCompare(data.status) === 0) {
          this.displayInvLineItemList = data.data;
          this.invoiceItemList.next(this.displayInvLineItemList);
          this.invoiceItemList.subscribe((data: InvoiceItemList[]) => {
            this.dataSource.data = data;
          });
        }
      },
      error => {
        let errorStatus;
        let errorMessage;
        if (error.error.message !== undefined) {
          errorStatus = error.error.status;
          errorMessage = error.error.message.substring(0, 90);
        } else {
          errorStatus = error.status;
          errorMessage = error.error;
        }
        this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage , 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['danger-snackbar']
        });
      }
    );
  }

}
