import { GlobalApiService } from './../../services/global/global-api/global-api.service';
import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SatDatepickerInputEvent, SatDatepickerRangeValue } from 'saturn-datepicker';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-bo-global-date-range',
    templateUrl: './bo-global-date-range.component.html',
    styleUrls: ['./bo-global-date-range.component.css']
})
export class BoGlobalDateRangeComponent implements OnInit {
    form: FormGroup;
    lastDateChange: SatDatepickerRangeValue<Date> | null;
    @Output() dateSelected = new EventEmitter<any>();
    @Input() disabled: boolean = false;
    @Input() isDateResetClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    // onDateChange = (e: SatDatepickerInputEvent<Date>) => this.lastDateChange = e.value as SatDatepickerRangeValue<Date>;
    constructor(private fb: FormBuilder, private globalApiService: GlobalApiService) {
        this.setDate();
    }

    setDate() {
        const today = new Date();
        let pastDay = new Date();

        pastDay.setDate(today.getDate() - (today.getDate() - 1));

        // this.form.reset();
        this.form = this.fb.group({
            date: [{
                begin: new Date(pastDay.getFullYear(), pastDay.getMonth(), pastDay.getDate()),
                end: new Date(today.getFullYear(), today.getMonth(), today.getDate())
            }]

        });

    }

    ngOnInit() {
        this.dateSelected.emit(this.form.get('date').value);
        this.globalApiService.clearDocumentSearchDate.subscribe((value: any) => {
            if (value == true) {
                this.setDate();
            }
        });

        this.isDateResetClicked.subscribe((data: any) => {
            if(data){
                this.setDate();
            }
        });
    }
    onDateChange(event) {
        this.lastDateChange = event.value;
        this.dateSelected.emit(this.lastDateChange);
    }
    
}
