import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetSrvSharedService } from '../../../../shared/services/get/get-srv-global/get-srv-shared.service';
import { GlobalSrvConstantsService } from '../../../../shared/services/global/global-constants/global-srv-constants.service';
import { BehaviorSubject } from 'rxjs';
import { GetEInvoiceService } from '../../../../shared/services/get/get-srv-eInv/get-eInvoice.service';

@Component({
    selector: 'app-sub-einv-req-details-dialog',
    templateUrl: './sub-einv-req-details-dialog.component.html',
    styleUrls: ['./sub-einv-req-details-dialog.component.css']
})
export class SubEinvReqDetailsDialogComponent implements OnInit {

    requestID: any;
    requestType : any;
    currentPage: any;
    pageSize: any;

    dataList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    columnList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    dataListPageCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    dataListSelection: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    isTableMultiSelect: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    constructor(
        private snackBar: MatSnackBar,
        private getEInvoiceService: GetEInvoiceService,
        private getColumnList: GetSrvSharedService,
        @Inject(MAT_DIALOG_DATA) data) {
        this.requestID = data.title;
        this.requestType = data.requestType;
    }

    ngOnInit() {
       // this.getDisplayedColumnList('EI_Snapshot_Header');
       if(this.requestType == 'EInvoice')
       {
            this.getDisplayedColumnList('EI_Snapshot_HDetails');
       }
       else
       {
            this.getDisplayedColumnList('Ew_Snapshot_HDetails');
       }
      
    }

    getDisplayedColumnList(tableType: string) {
        this.getColumnList.getColumnList(tableType).subscribe
            (
                data => {
                    if ('200'.localeCompare(data.status) === 0) {
                        this.columnList.next(data.data);
                    }
                },
                error => {
                    let errorStatus;
                    let errorMessage;
                    if (error.error.message !== undefined) {
                        errorStatus = error.error.status;
                        errorMessage = error.error.message.substring(0, 90);
                    } else {
                        errorStatus = error.status;
                        errorMessage = error.error;
                    }
                    this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                        duration: GlobalSrvConstantsService.SNACKBARDURATION,
                        panelClass: ['danger-snackbar']
                    });
                }
            );
    }

    getRequestDetail(currentPage, pageSize) {
        this.getEInvoiceService.getEInvoiceRequestDetail(this.requestID, pageSize, currentPage, this.requestType ).subscribe(
            data => {
                if ('200'.localeCompare(data.status) === 0) {
                    this.dataList.next(data.data.dataList);
                    this.dataListPageCount.next(data.data.count);
                }
            },
            error => {
                let errorStatus;
                let errorMessage;
                if (error.error.message !== undefined) {
                    errorStatus = error.error.status;
                    errorMessage = error.error.message.substring(0, 90);
                } else {
                    errorStatus = error.status;
                    errorMessage = error.error;
                }
                this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            }
        );
    }

    receiveEInvoicePageEvent(event: any) {
        this.currentPage = event.currentPage;
        this.pageSize = event.pageSize;
        this.getRequestDetail(this.currentPage, this.pageSize);
    }

    receiveDataSelection(event: any) {
    }
}
