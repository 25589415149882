import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrganizationTemplate, OutputInterface, SaveModules } from '../../models/post/pst-md-file-account-setup/post-file-account-setup-data';
import { AppConfigService } from '../common/app-config/app-config.service';
import { GlobalApiService } from '../global/global-api/global-api.service';

@Injectable({
  providedIn: 'root'
})
export class AccountSetupService {


  constructor(private _httpClient: HttpClient) { }
  ewayBillAPIBaseUrl = AppConfigService.ApiEnvoiceEwaybillServiceUrl;
  gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;

  addOrganizationDetails(organizationName: string, groupName: string): Observable<any> {
    return this._httpClient.get(
      this.ewayBillAPIBaseUrl + '/AccountSetup/AddOrganization?organizationName=' + organizationName + '&groupName=' + groupName
    );

  }

  getOrganizationConfig(type: string,organizationDetails:string, internalCall: boolean): Observable<any> {
    return this._httpClient.get(
      this.ewayBillAPIBaseUrl + '/AccountSetup/GetOrganizationConfig?type=' + type + '&organizationDetails=' + organizationDetails + '&internalCall=' + internalCall

    );

  }

  getAPIOrganizationConfig(type: string,organizationDetails:string, sourceID:number,internalCall: boolean): Observable<any> {
    return this._httpClient.get(
      this.ewayBillAPIBaseUrl + '/AccountSetup/GetOrganizationConfig?type=' + type + '&organizationDetails=' + organizationDetails + '&sourceID=' + sourceID + '&internalCall=' + internalCall

    );

  }

  saveModuleDetails(moduleDetails: SaveModules): Observable<any> {
    console.log(moduleDetails);
    return this._httpClient.post(
      this.ewayBillAPIBaseUrl + '/AccountSetup/AddOrganizationConfig', moduleDetails
    );
  }

  saveOrganizationTemplate(moduleDetails: OrganizationTemplate): Observable<any> {
    return this._httpClient.post(
      this.ewayBillAPIBaseUrl + '/AccountSetup/AddOrganizationTemplate', moduleDetails
    );
  }

  saveInputInterface(accountSetupUI: any[], chkApi: boolean, chkSavePayload: boolean): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/AccountSetup/addOrganization?organizationName=' + accountSetupUI + '&groupName=' + chkApi + '&groupName=' + chkSavePayload,
      {
      },

    );
  }

  saveOutputInterface(outputInterface: OutputInterface): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/AccountSetup/saveItcCashBalance', outputInterface
    );
  }

  saveHeaderLineItems(headerList: any[], LineItemList:  any[], inwardHeaderList:  any[]): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/AccountSetup/addOrganization?organizationName=' + headerList + '&groupName=' + LineItemList + '&groupName=' + inwardHeaderList,
      {
      },

    );
  }

  getOrganizationDetails(pageSize: number, currentPage: number): Observable<any> {
    return this._httpClient.post(
      this.ewayBillAPIBaseUrl + '/AccountSetup/GetOrganizationDetails?pageSize=' + pageSize + '&currentPage=' + currentPage,
      {
      },

    );
  }

  removeTemplateMapping(templateId: number,sourceId: number,organizationDetails:string): Observable<any> {
    return this._httpClient.get(
      this.ewayBillAPIBaseUrl + '/AccountSetup/RemoveTemplateMapping?templateId=' + templateId + '&sourceId=' + sourceId + '&organizationDetails=' + organizationDetails

    );

  }

  removeOrganization(organizationDetails: string,internalCall: boolean): Observable<any> {
    return this._httpClient.get(
      this.ewayBillAPIBaseUrl + '/AccountSetup/RemoveOrganization?organizationDetails=' + organizationDetails + '&internalCall=' + internalCall
    );

  }

  updateHeaderOrLineItemFields(mappingForOtherInfoId: number,clientFieldName:string,organizationDetails:string): Observable<any> {
    return this._httpClient.get(
      this.ewayBillAPIBaseUrl + '/AccountSetup/UpdateHeaderOrLineItemFields?mappingForOtherInfoId=' + mappingForOtherInfoId + '&clientFieldName=' + clientFieldName+ '&organizationDetails=' + organizationDetails

    );

  }

  UpdateConfigOrganizationValidationMapping(result,organizationDetails: string): Observable<any> {
    return this._httpClient.post(
      this.ewayBillAPIBaseUrl + '/AccountSetup/UpdateConfigOrganizationValidationMapping?organizationDetails=' + organizationDetails , result,
      {
      },

    );

  }

 getFilterData(organizationDetails: string): Observable<any> {
  return this._httpClient.get(
    this.ewayBillAPIBaseUrl + '/AccountSetup/FilterData?organizationDetails=' + organizationDetails

  );

}

getFinancialYear(): Observable<any> {
  return this._httpClient.get(
    this.ewayBillAPIBaseUrl + '/AccountSetup/GetFinancialYear'
    );
}

postSchedulerDetails(getSchedulerReportViewModel): Observable<any> {
  return this._httpClient.post(
    this.ewayBillAPIBaseUrl + '/AccountSetup/InsertSchedulerRequest', getSchedulerReportViewModel
  )
} 

getSchedulerData(requestDetails): Observable<any> {
  
  return this._httpClient.post(
    this.ewayBillAPIBaseUrl + '/AccountSetup/GetSchedulerReportsHistory',requestDetails
  );
}

removeSchedulerRequest(requestId: number,organizationDetails:string,isDelete:boolean): Observable<any> {
  return this._httpClient.get(
    this.ewayBillAPIBaseUrl + '/AccountSetup/RemoveSchedulerRequest?requestId=' + requestId + '&organizationDetails=' + organizationDetails + '&isDelete=' + isDelete,
    {
    },

  );

}

getFrequencyDescription(): Observable<any> {
  return this._httpClient.get(`${this.ewayBillAPIBaseUrl}/AccountSetup/GetFrequencyDescription`);
}

downloadLogsForScheduler(requestId: number,reportType:string,OrganizationDetails:string): Observable<any> {
  return this._httpClient.get(
    this.ewayBillAPIBaseUrl + '/AccountSetup/DownloadSchedulerLog?requestId=' + requestId + '&reportType=' + reportType + '&organizationDetails=' + OrganizationDetails, { responseType: 'blob' }
  );
}

getReturnTrackCountbyGSTIN(period:string): Observable<any> {
  return this._httpClient.get(
    this.ewayBillAPIBaseUrl + '/AccountSetup/GetReturnTrackCountbyGSTIN?period=' + period , { responseType: 'blob' }
  );
}

AddGSTINValidationConfiguration(OrganizationDetails:string, GSTIN: string,UserName:string,Password:string, IsActive:boolean): Observable<any> {
  return this._httpClient.post(
    this.ewayBillAPIBaseUrl + '/AccountSetup/AddGSTINValidationConfiguration?OrganizationDetails=' + OrganizationDetails + '&GSTIN=' + GSTIN + '&UserName=' + UserName+ '&Password=' + Password+ '&IsActive=' + IsActive,
    {
    },

  );

}

GetGSTINValidationConfiguration(organizationDetails:string): Observable<any> {
  return this._httpClient.get(
    this.ewayBillAPIBaseUrl + '/AccountSetup/GetGSTINValidationConfiguration?organizationDetails=' + organizationDetails
  );

}

GetConfigEntityGstin(): Observable<any> {
return this._httpClient.get(
  GlobalApiService.GLOBALREPORTURL + '/irp/GetConfigEntityGstin'
);
}

postAccountSetupRequestDetails(postAccountSetupRequest): Observable<any> {
  return this._httpClient.post(
    this.ewayBillAPIBaseUrl + '/AccountSetup/PostAccountSetupRequest', postAccountSetupRequest
  )
}

AddReportConfiguration(OrganizationDetails:string, IsActive:number): Observable<any> {
  return this._httpClient.post(
    this.ewayBillAPIBaseUrl + '/AccountSetup/AddReportConfiguration?OrganizationDetails=' + OrganizationDetails + '&IsActive=' + IsActive,
    {
    },

  );

}

AddSchedulerReportConfiguration(OrganizationDetails:string, ScheduledTime:string): Observable<any> {
  return this._httpClient.post(
    this.ewayBillAPIBaseUrl + '/AccountSetup/AddSchedulerReportConfiguration?OrganizationDetails=' + OrganizationDetails + '&ScheduledTime=' + ScheduledTime,
    {
    },

  );

}

}
