import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchUploadModel } from '../../models/get/get-md-global/boew-inv/get-boew-inv-search';
import { CashLedgerPdfInputModel, GenerateDocSave, gstRefundInputDetails,TableCashBalanceDetails,ValidationInputDetails } from '../../models/gstRefund/gst-refund';
import { AppConfigService } from '../common/app-config/app-config.service';
import { CashItcBalanceNew } from '../../models/post/pst-md-file-ledger/post-file-ledger-data';
import { MasterSource } from '../../Constants';

@Injectable({
  providedIn: 'root'
})
export class GstRefundService {

  constructor(private _httpClient: HttpClient) { }
  gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;
  
  getGstinDropdata(returnType: string): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR1Returns/FilterData' + '/' + returnType
    );
  }


  getAutoPopulatedData(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/downloadAutoPopulated', inputs, { responseType: 'blob' }
    );
  }

  getAutoPopulatedData_DataRetrieval(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/downloadAutoPopulatedBRCFIRC', inputs, { responseType: 'blob' }
    );
  }

  GetRefundBRCEGMDetails(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/BRCEGMDetails', inputs, { responseType: 'blob' }
    );
  }

  getEGMEBrcRequestHistoryDetails(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/EGMEBrcRequestGridData', inputs
    );
  }

  getRefundAnnexureData(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/downloadRefundAnnexure', inputs, { responseType: 'blob' }
    );
  }

  downloadStatement3_Outwards(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/downloadStatement3', inputs, { responseType: 'blob' }
    );
  }

  checkAutoPopulatedData(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/GSTR1DataCheck', inputs
    );
  }
  RefundApplicationRequest(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/refundRequestEntry', inputs
    );
  }

  FetchEGMEBRCDataRequest(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/EGMeBRCRequestEntry', inputs
    );
  }

  saveGeneratedDoc(inputs:GenerateDocSave): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/SaveGeneratedDoc', inputs
    );
  }
  getGeneratedDoc(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/GetGeneratedDocument', inputs
    );
  }


  downloadStatment3Json(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/downloadstatement3Json', inputs
    );
  }
  downloadDeclarations(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/downloadDeclarations', inputs, { responseType: 'blob' }
    );
  }

  downloadRepo(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/downloadRepoFiles', inputs
    );
  }

  downloadGSTReturns(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/GenerateGSTReturnSummary', inputs
    );
  }

  getOutwardInwardSummaryDetails(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/GetOutInwSummary', inputs
    );
  }
  geteBRCSummaryDetails(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/GetEBrcSummary', inputs
    );
  }
  getValidationStatusDetails(requestID:number): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/GetValidationDetails', requestID
    );
  }
  doValidation(requestID:number, reconType : string): Observable<any> {
    let inputs = new ValidationInputDetails();
    inputs.RequestID = requestID;
    inputs.ReconType = reconType;

    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/ValidateRefundRequest', inputs
    );
  }
  downloadGSTR2A(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/GenerateGSTR2AReport', inputs, { responseType: 'blob' }
    );
  }
  getType7SummaryDetails(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/GetType7SummaryDetails', inputs
    );
  }
  saveType7Summary(inputs:any): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/SaveType7Summary', inputs
    );
  }

  getClaimSummaryDetails(inputs:any): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/GetClaimEligibleDetails', inputs
    );
  }
  saveClaimSummary(inputs:any): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/SaveClaimEligible', inputs
    );
  }

  checkForRefudClaimEligibility(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/CheckForRefudClaimEligibility', inputs
    );
  }
  updateClaimEligibleData(inputs:any): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/updateClaimEligibleData', inputs
    );
  }

  enableGenerateDocumentationTab(inputs:gstRefundInputDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/GetGenerateDocStatus', inputs
    );
  }

  getFileHistory(pageSize, currentPage,filterValue,filename,date): Observable<any> {
    const searchFileModel: SearchUploadModel = {} as SearchUploadModel;
    searchFileModel.pageSize = pageSize;
    searchFileModel.currentPage=currentPage;
    searchFileModel.fileName=filename == '' ? null :filename;
    searchFileModel.uploadedOn=date == '' ? null : date;
    searchFileModel.filterModel =filterValue;
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/gstRefund/uploadedList' ,searchFileModel
       );
}

GetEditableCashBalanceDetails(cashItcBalance: CashItcBalanceNew): Observable<any> {
  return this._httpClient.post(
    this.gstrServiceBaseUrl + '/gstRefund/GetCashBalance', cashItcBalance
  );
}

SaveEditableCashBalanceDetails(editedCashBalanceData: TableCashBalanceDetails[]): Observable<any> {
  return this._httpClient.post(
    this.gstrServiceBaseUrl + '/gstRefund/SaveCashBalance', editedCashBalanceData
  );
}

generateCashLedgerPDF(requestModel: CashLedgerPdfInputModel): Observable<any> {
  return this._httpClient.post(
    this.gstrServiceBaseUrl + '/gstRefund/CashLedgerPdfDownload', requestModel
  );
}

getUploadTemplates(): Observable<any> {
  return this._httpClient.get(
      this.gstrServiceBaseUrl + '/gstRefund/template/' + MasterSource.UI
  );
}

}
