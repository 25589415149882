export interface AccessTokenReq {
    organizationName: string;
    tokenName: string;
    tokenTerm: number;
    tokenPeriod: number;
}

export class RevokeAccessTokenReq {
    ClientId: string;
    Token: string;
}