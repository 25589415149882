import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subject } from 'rxjs';
import { FileHistory, ErrorFileHistory,DeleteFileHistory } from '../../../shared/models/get/get-md-beow-upload/get-file-history';
import { DataColumnList } from '../../../shared/models/get/get-md-global/get-column-list';
import { GbPagination } from '../../../shared/models/global/pagination/gb-pagination';
import { GlobalApiService } from '../../../shared/services/global/global-api/global-api.service';
import { GlobalSrvConstantsService } from '../../../shared/services/global/global-constants/global-srv-constants.service';
import { GetUploadFileService } from '../../../shared/services/get/get-srv-boew-upload/get-upload-file.service';
import { Dialog, DialogTypes } from 'src/app/shared/Constants';
import { MatRadioChange } from '@angular/material';
import { GetSrvSharedService } from 'src/app/shared/services/get/get-srv-global/get-srv-shared.service';

 

@Component({
    selector: 'app-sub-file-upload-APOB-grid',
    styleUrls: ['./sub-file-upload-APOB-grid.component.css'],
    templateUrl: './sub-file-upload-APOB-grid.component.html'
})
export class SubFileUploadAPOBGridComponent implements OnInit {

    constructor(private _getUplaodFile: GetUploadFileService, private globalApiService: GlobalApiService, private snackBar: MatSnackBar, private getColumnList: GetSrvSharedService) {

    }
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    displayFileHistory: FileHistory[];
    sortedData: FileHistory[];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @Input() deleteFileHistory: Subject<DeleteFileHistory[]>;
    @Input() errorFileHistory: Subject<ErrorFileHistory[]>;
    @Input() fileHistory: Subject<FileHistory[]>;
    @Input() allColumnList: Subject<DataColumnList[]>;
    @Input() errorDocumentColumnList: Subject<DataColumnList[]>;
    @Input() deleteDocumentColumnList: Subject<DataColumnList[]>;
    @Input() pageCount: Subject<number>;
    @Input() errorPageCount: Subject<number>;
    @Input() deletePageCount: Subject<number>;
    @Input() filterPagination: boolean = false;
    @Output() pageEvents = new EventEmitter<GbPagination>();
    @Output() errorPageEvents = new EventEmitter<GbPagination>();
    @Output() errorSearchEvents = new EventEmitter<any>();
    @Output() resetGridData = new EventEmitter<any>();    
    @Output() onRemoveFileHistory = new EventEmitter<any>();
    @Output() deletePageEvents = new EventEmitter<GbPagination>();
    @Input() hideDownloadButton: Subject<boolean>;
    dataSource = new MatTableDataSource<FileHistory>(null);
    isDownloading = false;
    @Input() waitingFileListResponse: boolean;
    @Input() waitingErrorFileListResponse: boolean;
    dataColumns: DataColumnList[] = [];
    allColumns: DataColumnList[] = [];
    errorDataColumns: DataColumnList[] = [];
    gbPagination: GbPagination;
    errorDocumentNumber: string;
    disabledownloadButton: boolean = true;
    disableSearchBox: boolean = false;
    isDowload: boolean = false;
    deleteDataColumns: DataColumnList[] = [];
    supplyType: string = "1";
    supplyTypeSelected: string = "Outwards";
    disabledownloadButtonXML: boolean = false;
    pageSize = 5;
    currentPage = 1;
    deleteFileData: DeleteFileHistory[];

    dataList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    columnList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    columnFilterList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    errorDataList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    errorColumnList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    dataListPageCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    dataListSelection: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    isTableMultiSelect: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    dataListErrorPageCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    deleteDataList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    deleteColumnList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    dataListDeletePageCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    @Output() refeshGrid = new EventEmitter<any>();   
    
    ngOnInit() {
        this.fileHistory.subscribe((data: any) => {
            if (data === undefined) {
                return;
            }
            this.dataList.next(data);
           // setTimeout(() => { this.waitingFileListResponse = false; }, 1000);
        });
        this.allColumnList.subscribe((data: any) => {
            this.dataColumns = [];
            this.dataColumns = data;
            if (this.dataColumns == null) {
                return;
            }
            this.columnList.next(this.dataColumns);
            let dataFilter = [];
            dataFilter.push({alias:"Select Filter",columnName:"Select Filter",createdDate:null,isDefault:false,tableType:"Document_Upload"});
            this.dataColumns.forEach(element => {
                if(element.columnName != "statusMessage" && element.columnName != "templateName" && element.columnName != "Document_Upload_Delete_GSTR1Template" && element.columnName != "uploadedBy"){
                    dataFilter.push(element);
                } 
            });
            this.columnFilterList.next(dataFilter);
        });

        this.errorFileHistory.subscribe((data: any) => {
            if (data === undefined) {
                return;
            }
            if (data.length == 0) {
                this.errorDataList.next(data);
                this.disableSearchBox = false;
                this.snackBar.open('No Data', 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['alert-snackbar']
                });
                return;
            }
            this.disableSearchBox = true;
            this.errorDataList.next(data);
            this.disabledownloadButton = false;
        });
        this.errorDocumentColumnList.subscribe((data: any) => {
            this.errorDataColumns = [];
            this.errorDataColumns = data;
            if (this.errorDataColumns == null) {
                return;
            }
            this.errorColumnList.next(this.errorDataColumns);
        });

        this.deleteFileHistory.subscribe((data: any) => {
            if (data === undefined) {
                return;
            }
            this.deleteDataList.next(data);
        });

        this.deleteDocumentColumnList.subscribe((data: any) => {
            this.deleteDataColumns = [];
            this.deleteDataColumns = data;
            if (this.deleteDataColumns == null) {
                return;
            }
            this.deleteColumnList.next(this.deleteDataColumns);
        });

        this.pageCount.subscribe((data: number) => {
            this.dataListPageCount.next(data);
        });

        this.errorPageCount.subscribe((data: number) => {
            this.dataListErrorPageCount.next(data);
        });

        this.deletePageCount.subscribe((data: number) => {
            this.dataListDeletePageCount.next(data);
        });

        this.hideDownloadButton.subscribe((data: boolean) => {
            this.disabledownloadButtonXML = data;
        });
    }

    downloadFileUpload(fileID, fileName) {
        this.dataSource.data.find(item => item.fileId === fileID).isFileDownloading = true;
        this.globalApiService.downloadFile('inv', fileID).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = fileName;
            anchor.href = url;
            anchor.click();
            this.dataSource.data.find(item => item.fileId === fileID).isFileDownloading = false;
        },
            error => {
                this.dataSource.data.find(item => item.fileId === fileID).isFileDownloading = false;
                const filename = this.dataSource.data.find(item => item.fileId === fileID).fileName;
                this.snackBar.open(
                    'Error Downloading ' + filename + '. | Status: ' + error.status + '. | Error Message: ' + error.name, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            });
    }
 
    downloadErrorFile(fileID, fileName) {
        this.isDownloading = true;
        this.globalApiService.downloadFile('inv', fileID).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = 'Error_' + fileName;
            anchor.href = url;
            anchor.click();
            this.isDownloading = false;
        });
    }

    ngDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    receivePageEvent(event: any) {
        this.gbPagination = event;
        this.pageEvents.emit(this.gbPagination);
    }

    receiveErrorPageEvent(event: any) {
        this.gbPagination = event;
        this.errorPageEvents.emit(this.gbPagination);
    }

    receiveDeletePageEvent(event: any) {
        this.getDeleteFileHistory(event.pageSize, event.currentPage); 
        // this.gbPagination = event;
        // this.deletePageEvents.emit(this.gbPagination);
    }

    receiveDataSelection(event: any) {
    }

    searchErrorDocuments() {
        if (this.errorDocumentNumber == null || this.errorDocumentNumber.trim() == "") {
            this.snackBar.open('Please Enter The Document Number', 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['alert-snackbar']
            });
            return;
        }
        this.errorSearchEvents.emit({documentNo:this.errorDocumentNumber.trim(), supplyType: this.supplyTypeSelected.trim()});
    }

    downloadErrorFileHistory() {
        this.isDownloading = true;
        this._getUplaodFile.downloadErrorFileHistory(this.errorDocumentNumber.trim(),this.supplyTypeSelected.trim()).subscribe((data: any) => {
            const blob = new Blob([data], { type: data.type });
            if (data.type == "application/json") {
                this.snackBar.open('File not found', 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['alert-snackbar']
                });
                this.isDownloading = false;
                return;
            }
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = this.errorDocumentNumber;
            if(data.type == "text/csv")
            anchor.download = "Data.csv";
            anchor.href = url;
            anchor.click();
            this.isDownloading = false;
        });
    }
    onReset() {
        this.disableSearchBox = false;
        this.disabledownloadButton = true;
        this.errorDocumentNumber = "";
        this.errorDataList.next([]);
        this.dataListErrorPageCount.next([]);
    }
    onRemoveFileFromDataUpload($event){
        this.onRemoveFileHistory.emit($event.uploadId);
    }

    onSupplyTypeChange(mrChange: MatRadioChange) {
        if (mrChange.value == 0) {
          this.supplyTypeSelected = "Inwards";
        }
        else if (mrChange.value == 1){
          this.supplyTypeSelected = "Outwards";
        }
      }

    onSearchByErrorDocumentNumberTabClick(){
        this.getErrorColumnList()
    }

    getErrorColumnList() {
        this.getColumnList.getColumnList('Error_Document').subscribe(
            data => {
                if ('200'.localeCompare(data.status) === 0) {
                    this.errorDocumentColumnList.next(data.data);
                }
            },
            error => {
                let errorStatus;
                let errorMessage;
                if (error.error.message !== undefined) {
                    errorStatus = error.error.status;
                    errorMessage = error.error.message.substring(0, 90);
                } else {
                    errorStatus = error.status;
                    errorMessage = error.error;
                }
                this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            }
        );
    }

    async onDeletedFileHistoryTabClick(){
        await this.getDeleteColumnList();
        this.getDeleteFileHistory(this.pageSize, this.currentPage); 
    }

    async getDeleteColumnList() {
        this.getColumnList.getColumnList('DeleteUploadDocument').subscribe(
            data => {
                if ('200'.localeCompare(data.status) === 0) {
                    this.deleteDocumentColumnList.next(data.data);
                }
            },
            error => {
                let errorStatus;
                let errorMessage;
                if (error.error.message !== undefined) {
                    errorStatus = error.error.status;
                    errorMessage = error.error.message.substring(0, 90);
                } else {
                    errorStatus = error.status;
                    errorMessage = error.error;
                }
                this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            }
        )
    }

    getDeleteFileHistory(pageSize: any, currentPage: any) {
        this._getUplaodFile.getDeleteFileHistory(pageSize, currentPage).subscribe(
            data => {
                if ('200'.localeCompare(data.status) === 0) {
                    this.deleteFileData = data.data.dataList;
                    this.deletePageCount.next(data.data.count);
                    this.deleteFileHistory.next(this.deleteFileData);
                }
            },
            error => {
                let errorStatus;
                let errorMessage;
                if (error.error.message !== undefined) {
                    errorStatus = error.error.status;
                    errorMessage = error.error.message.substring(0, 90);
                } else {
                    errorStatus = error.status;
                    errorMessage = error.error;
                }
                this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            }
        );
    }

    refresh(){
        this.refeshGrid.emit();
    }
    
}
