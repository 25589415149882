
export class PrepareReturnModel
{
  EntityGstin:string[]=[];
  ReportedPeriod:string;
  IsPrepareData:boolean;
  TransactionType : string;
  TaxableValueTolerance:number;
  TaxTolerance:number;
  PrepareAndDownload:boolean;
  IsMultiGSTIN:boolean;
}

export class PrepareSalesVsIRPModel
{
  EntityGstin:string[]=[];
  ReportedPeriod:string[]=[];
  IsPrepareData:boolean;
  TransactionType : string;
  TaxableValueTolerance:number;
  TaxTolerance:number;
  PrepareAndDownload:boolean;
}

export class SectionViewModel{
   transactionType : string;
		total_Record :any;
    taxval :any;
    val :any;
		iamt :any;
		camt :any;
		 samt :any;
		 csamt :any;
		 expt_amt :any;
		 nil_amt :any;
		 ngsup_amt :any;
		 net_issue :any;
     cancel :any;
     gstin:string;
     period:string;
     customerName:string;
     inum:string;
     idt:string;
     pos:string;
     rate :number;
     applicablerate:number;
     description: string;
     supply : string;
     from:string;
     to:string;
     doc_type : any;
     totalnum : any;
     num : number;
     total_qty : any;
		uqc : string;
		code : string;
    gross:any;
    id:number;
    ntty : string;
		oinum : string;
		oidt : string;
    customerGstin: string;
    isDeleted : boolean = false;
    isAdded: boolean = false;
    rowNumber : number =0;
    omon:string;

}
export class PrepareReturnsRequestsViewModel{
  EntityGstin  : string;
  ReportedPeriod : string;
  TransactionType : string;
  Currentpage : number;
  PageSize : number;
  ReturnType : string;
  IsMultiGSTIN :boolean;
  MultiGSTIN: string[];
}

export class SectionDetailsModel{
  gstin  : string;
  period : string;
  transactionType : string;
  currentpage : number;
  pageSize : number;
  source : string;
  type:string;
  prepareData: number ;
  subType: string ;
}

export class GSTR1SummaryResponseModel
{

  transactionCategory:string; 
  countOfDocuments:Number; 
  totalTaxableValue:Number; 
  totalIGST:Number;  
  totalCGST:Number; 
  totalSGST:Number; 
  totalCessAmount:Number; 
}

export class GSTR1ViewSummaryResponseModel
{
  entityGSTIN : string;
  gstR1Period : string;
  transactionCategory:string; 
  dataUploadedOn : string;
  gstR1SummaryPreparedOn : string;
  getGSTR1eInvoiceOn : string;
  countOfDocuments:Number; 
  totalTaxableValue:Number;    
  totalCGST:Number; 
  totalSGST:Number; 
  totalIGST:Number; 
  totalCess:Number; 
}

export class UpdateDocsDetailsModel{
  gstin : string;
  period : string;
  doc_type : number;
  num : number;
  from : string;
   to :string;
   totalnum : any;
 cancel : any;
  net_issue : any;
  isNew: boolean;
  isDelete: boolean;
  id:number;
}
export interface TransactionType
{
  Name:string;
}


export class GSTR1SummaryDownloadViewModel{
  entityGstin:string;
  reportedPeriod:string;
}

export class GSTR3BSummaryDownloadViewModel{
  entityGstin:string;
  reportedPeriod:string;
  userName?: string;
  reconType?: string;
}


export class GSTR1RemoveTransactionalDataViewModel{
  entityGstin:string;
  reportedPeriod:string;
  transactionTypes:string[];
  reason:string;
}

export class GSTR3BDataViewModel{
 natueOfSuppliesCode: number;
 natureOfSupply: string;
 tableNo: string;
 gstin:string;
 placeOfSupply: number;
 totalTaxableValue :Number;
 igst : Number;
 cgst : Number;
 sgst : Number;
 cess : Number;
 period : string;
interStateSupplies:  Number;
intraStateSupplies:  Number;
isNew : boolean;
}

export class GSTR9DataViewModel{
  getgstr9autocalcdetailsid : Number;
  typename: string;
  organizationId :Number;
  gstin : string;
  period :string;
  tbl :string;
  description :string;
  sec :string;
  chksum :string;
  txval :any;
  iamt :any;
  camt :any;
  samt :any;
  csamt :any;
  txpyble :any;
  txpaidCash:any;
  taxPaidItcIamt:any;
  taxPaidItcCamt :any;
  taxPaidItcSamt :any;
  taxPaidItcCsamt :any;
  createdBy:string;
  createdOn: Date;
  uqc:string;
  qty:any;
  hsn_sc:string;
  rt:any;
  val:Number;
  srno : string;
  flag : string;
  ret_period: string;
  num:Number;
  desc:string;
  intr:any;
  fee:any;
  pen:any;
  isDeleted : Number = 0;
}

export class GSTR3BPrepareMode{
  EntityGstin : string;
  ReportPeriod: string;
  PrepareData : number;
  OutputType : string = 'Summary';
  Source : string ;
  OrganizationId: number;
  ReconAcceptType:string='No';
  isPullBoltOnData: boolean = false;
  userName?: string;
}

export class GSTR3BSaveStatusModel{
  EntityGstin : string;
  ReportPeriod: string;
  RefId:string;
}
export class GSTR2XViewModel{
  id:number;
  gstin:string;
  period:string;
  month:string;
  omon:string;
  customerGstin: string;
  transactionType : string;
  totCount :number;
  totACount:number;
  totATax:number;
  totAAmt:number;
  totRCount:number;
  totRTax:number;
  totRAmt:number;
  taxable:number;
   iamt :any;
   camt :any;
    samt :any;
    csamt :any;
    ngsup_amt :any;
    netamount :any;
    gross:any;
    status :string;
}   
export class Section2XDetailsModel{
  gstin  : string;
  period : string;
  transactionType : string;
  currentpage : number;
  pageSize : number;
  type:string;
}
export class GSTR2XViewModelExample{
  transactionType : string;
  totCount :number;
  totACount:number;
  totATax:number;
  totAAmt:number;
  totRCount:number;
  totRTax:number;
  totRAmt:number;
} 