import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../common/app-config/app-config.service';
 
@Injectable({
  providedIn: 'root'
})
export class MoowrReportService {
  constructor(private _httpClient: HttpClient) { }
  ewayBillAPIBaseUrl = AppConfigService.ApiEnvoiceEwaybillServiceUrl;
  gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;

  getMoowrLicence(Period): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/Moowr/getlicence?Period='+ Period);    
  }
  moowrReportRequest(Period,licenceNumber,ReportType): Observable<any> {
    return this._httpClient.get(this.gstrServiceBaseUrl + '/Moowr/generateReport?Period='+ Period +'&licenceNumber='+ licenceNumber+'&ReportType='+ ReportType)
  }
  
  getMoowrReportData(ReportType,PageSize,CurrentPage): Observable<any> {
    return this._httpClient.get(this.gstrServiceBaseUrl + '/Moowr/MoowrRequestReport?ReportType='+ ReportType +'&PageSize='+ PageSize+'&CurrentPage='+ CurrentPage)
  }

  getMoowrBGNo(): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/Moowr/getBGNo');    
  }

  getMoowrBondNo(): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/Moowr/getBondNo');    
  }

  generateMoowrPdfReport(fileID): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/Moowr/generateMoowrPdf?fileID=' + fileID ,
      {
      },

    );
  }
 
  moowrBGBondCreditRequest(BGBondNoSelected,MOOWRLicenseNo,BGBondCreditorNew,BGBondCreditAmount,selectedDate,ReportType,flow): Observable<any> {
    return this._httpClient.get(this.gstrServiceBaseUrl + '/Moowr/insertBondBGCreditRequest?BGBondNo='+ BGBondNoSelected +'&LicenseNo='+ MOOWRLicenseNo+'&BGBondCreditorNew='+ BGBondCreditorNew
    +'&selectedDate='+ selectedDate+'&BGBondCreditAmount='+ BGBondCreditAmount+'&ReportType='+ ReportType+'&Flow='+ flow)
  }

  getBGCreditDetails(PageSize,CurrentPage): Observable<any> {
    return this._httpClient.get(this.gstrServiceBaseUrl + '/Moowr/MOOWRGetBGCreditDetails?PageSize='+ PageSize+'&CurrentPage='+ CurrentPage)
  }

  getBondCreditDetails(PageSize,CurrentPage): Observable<any> {
    return this._httpClient.get(this.gstrServiceBaseUrl + '/Moowr/MOOWRGetBondCreditDetails?PageSize='+ PageSize+'&CurrentPage='+ CurrentPage)
  }

  getMoowrBGLicence(BGNo): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/Moowr/getBGlicence?BGNo='+ BGNo);    
  }

  getMoowrBondLicence(BondNo): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/Moowr/getBondlicence?BondNo='+ BondNo);    
  }

  deleteMoowrBGRequest(bgCreditID): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/Moowr/removeBGRequest?bgCreditID='+ bgCreditID);    
  }

  deleteMoowrBondRequest(bondCreditID): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/Moowr/removeBondRequest?bondCreditID='+ bondCreditID);    
  }

  InsertBGBondMasterDetails(formdata: any, moowrLicenceSelected: string, selectedBGBondDate1: string, selectedBGBondDate2: string, MOOWRBGBondNumber1: string, MOOWRBGBondNumber2: string, MOOWRBGRate: number): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/Moowr/InsertBGBondMasterDetails?MoowrLicenceSelected=' + moowrLicenceSelected + '&SelectedBGBondDate1=' + selectedBGBondDate1 + '&SelectedBGBondDate2=' + selectedBGBondDate1 + '&MOOWRBGBondNumber1=' + MOOWRBGBondNumber1 + '&MOOWRBGBondNumber2=' + MOOWRBGBondNumber2 + '&MOOWRBGRate=' + MOOWRBGRate, formdata);
  }

  getBondBGMasterDetails(PageSize,CurrentPage): Observable<any> {
    return this._httpClient.get(this.gstrServiceBaseUrl + '/Moowr/MOOWRMasterBondBGDetails?PageSize='+ PageSize+'&CurrentPage='+ CurrentPage)
  }

  deleteMoowrBondBGRequest(bondBGID): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/Moowr/MOOWRRemoveBondBGRequest?ID='+ bondBGID);    
  }

  getMoowrMasterLicence(): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/Moowr/getMasterlicence');    
  }

  downloadFile(): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/Moowr/GetBGBondMasterFile', {
         responseType: 'blob'
    });
}

}
