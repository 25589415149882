import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { BoConfirmationDialogComponent } from '../bo-confirmation-dialog/bo-confirmation-dialog.component';
import { Dialog, DialogTypes } from '../Constants';
import { transactionTypeSelected } from '../models/post/pst-md-file-returns/post-file-return-data';
import { GlobalSrvConstantsService } from '../services/global/global-constants/global-srv-constants.service';
import { SectionViewModel } from '../services/Returns/returns-model.service';

@Component({
  selector: 'app-editable-table',
  templateUrl: './editable-table.component.html',
  styleUrls: ['./editable-table.component.css']
})
export class EditableTableComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @Input() isReset: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  @Input() dataList: Subject<any[]>;
  @Input() pageCount: Subject<number>;
  @Input() listOfReportedPeriod: any[];
  @Input() type: string = "";
  @Input() uqcCodes: any[];
  @Input() listOfRate: any[];
  @Input() isSaved: boolean = false;
  @Input() posList: any[];
  @Input() gstin: string  = "";
  @Input() period: string  = "";
  @Input() DataSaved = new EventEmitter<any>();
  @Output() dataSelectedForEdit = new EventEmitter<any>();
  @Output() dataSelectedForReset = new EventEmitter<any>();
  @Output() paginationData = new EventEmitter<any>();
  @Output() fileUploadedEvent = new EventEmitter<FormData>();
  isResetWait : boolean = false;
  isValReq : boolean = false;
    pageLength : any;
  month : transactionTypeSelected[]=[];
  appRate = [0,65];
  dataSource : any[]=[];
  isEdit:boolean = true;
  editedData :SectionViewModel[]=[];
  finalDataSource =[];
  rateValues = [0,0.1,0.25,1,1.5,3,5,7.5,12,18,28];
  isHSN99 :boolean = true;
  constructor(private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = [];
    this.uqcCodes.push('NA');
    this.stateCode = this.gstin[0].substring(0,2);
    let monthData = Number(this.period.substring(0,2));
    let yearData = Number(this.period.substring(3,7));
    if(monthData > 4 && yearData > 2020){
     this.isValReq = true;
    }
    this.posList.forEach(x => {
      x.stateId =  x.stateId.toString();
      if( x.stateId.length == 1){
        x.stateId = "0" +x.stateId;
      }
      x.stateName = x.stateName.toUpperCase();
    })
    this.posList = this.posList.filter(x => x.stateName != "ANDHRA PRADESH BEFORE DIVISION" && x.stateId != "99");
    this.listOfReportedPeriod.forEach(x => {
      const model: transactionTypeSelected = {} as transactionTypeSelected;
       model.display = x;
       model.value = x.replace("-","");
       this.month.push(model);
    })
    this.pageCount.subscribe((data: number) => {
      this.pageLength = data;
  });
  this.dataList.subscribe((data: any) => {
    this.dataSource = [];
      if (data == null) {
          this.dataSource = [];
          return;
      }
      else{
        this.dataSource = data;
        this.finalDataSource = data;
        console.log("this.dataSource",this.dataSource)
        for(var i=0; i< this.dataSource.length ; i++){
          this.dataSource[i].rowNumber = i+1;
        }
        if(this.type == "HSN"){
          this.dataSource.forEach(element => {
            if(element.uqc != null && element.uqc != undefined)
            element.uqc =  element.uqc.toUpperCase();
          });
        }  
      }
      for (var i = 0; i < this.dataSource.length; i++) { 
        this.dataSource[i].gstin = this.gstin[0];
        this.dataSource[i].period = this.period;

        if(this.type == 'NIL'){
          this.dataSource[i].nil_amt = this.Comma(this.dataSource[i].nil_amt);
          this.dataSource[i].expt_amt = this.Comma(this.dataSource[i].expt_amt);
          this.dataSource[i].ngsup_amt = this.Comma(this.dataSource[i].ngsup_amt);
        }
        else if(this.type == 'ADVADJ' || this.type == 'ADVRCD'){
          if(this.dataSource[i].pos != "" && this.dataSource[i].pos != null && isNaN(this.dataSource[i].pos)){
              this.dataSource[i].pos = this.posList.filter(x=> x.stateName == this.dataSource[i].pos).map(x=> x.stateId)[0];    
          }
          this.dataSource[i].iamt = this.Comma(this.dataSource[i].iamt);
          this.dataSource[i].camt = this.Comma(this.dataSource[i].camt);
          this.dataSource[i].samt = this.Comma(this.dataSource[i].samt);
          this.dataSource[i].csamt = this.Comma(this.dataSource[i].csamt);
          this.dataSource[i].gross = this.Comma(this.dataSource[i].gross);
        }
        else if(this.type == 'B2CS' || this.type == 'B2CSA'){
          if(this.dataSource[i].pos != "" && this.dataSource[i].pos != null && isNaN(this.dataSource[i].pos)){
            this.dataSource[i].pos = this.posList.filter(x=> x.stateName == this.dataSource[i].pos).map(x=> x.stateId)[0];
          }
          this.dataSource[i].iamt = this.Comma(this.dataSource[i].iamt);
          this.dataSource[i].camt = this.Comma(this.dataSource[i].camt);
          this.dataSource[i].samt = this.Comma(this.dataSource[i].samt);
          this.dataSource[i].csamt = this.Comma(this.dataSource[i].csamt);
          this.dataSource[i].taxval = this.Comma(this.dataSource[i].taxval);
        }
        else if(this.type == 'HSN'){
          this.dataSource[i].iamt = this.Comma(this.dataSource[i].iamt);
          this.dataSource[i].camt = this.Comma(this.dataSource[i].camt);
          this.dataSource[i].samt = this.Comma(this.dataSource[i].samt);
          this.dataSource[i].csamt = this.Comma(this.dataSource[i].csamt);
          this.dataSource[i].taxval = this.Comma(this.dataSource[i].taxval);
          this.dataSource[i].total_qty = this.Comma(this.dataSource[i].total_qty);
          this.dataSource[i].val = this.Comma(this.dataSource[i].val);
        }
    }
  });

  this.isReset.subscribe((data: any) =>{
    this.isResetWait = data;
  })
  }

  getPageEvent(ev){
   this.paginationData.emit(ev);
  }

  calculateData(){
    for (var i = 0; i < this.dataSource.length; i++) { 
      if(this.type == 'HSN'){
        this.dataSource[i].iamt = this.Comma(this.dataSource[i].iamt);
        this.dataSource[i].camt = this.Comma(this.dataSource[i].camt);
        this.dataSource[i].samt = this.Comma(this.dataSource[i].samt);
        this.dataSource[i].csamt = this.Comma(this.dataSource[i].csamt);
        this.dataSource[i].taxval = this.Comma(this.dataSource[i].taxval);
        this.dataSource[i].total_qty = this.Comma(this.dataSource[i].total_qty);
        this.dataSource[i].val = this.Comma(this.dataSource[i].val);
      }
  }
  }

  resetData(){
   this.dataSelectedForReset.emit(true);
  }

  EditData(){
   this.isEdit = false;
   this.isHSN99 = false;
   if(this.dataSource.length == 0){
    this.addRow(0);
   }
  }

  SaveData(){

    if(this.editedData.length > 0){
      for(let data of this.editedData){ 
        data.transactionType = this.type;
        data.net_issue = data.net_issue == null || data.net_issue == "0" || data.net_issue == "null" || data.net_issue == "" ? 0 : data.net_issue;
        data.cancel = data.cancel == null || data.cancel == "0" || data.cancel == "null" || data.cancel == "" ? 0 : data.cancel;
        data.doc_type= data.doc_type == null || data.doc_type == "0" || data.doc_type == "null" || data.doc_type == "" ? 0 : data.doc_type;
        data.totalnum = data.totalnum == null || data.totalnum == "0" || data.totalnum == "null" || data.totalnum == "" ? 0 : data.totalnum;
        data.num = data.num == null ? 0 : data.num;
        data.total_qty = data.total_qty == null || data.total_qty == "0" || data.total_qty == "null" || data.total_qty == "" ? 0 : data.total_qty;
        data.gross = data.gross == null || data.gross == "0" || data.gross == "null" || data.gross == "" ? 0 : data.gross;
        data.val = data.val == null || data.val == "0" || data.val == "null" || data.val == "" ? 0 : data.val;
        data.taxval = data.taxval == null || data.taxval == "0" || data.taxval == "null" || data.taxval == "" ? 0 : data.taxval;
        data.rowNumber = data.rowNumber == null  ? 0 : data.rowNumber;
        data.id = data.id == null ? 0 : data.id;
        data.expt_amt = data.expt_amt == null || data.expt_amt == "0" || data.expt_amt == "null" || data.expt_amt == "" ? 0 : data.expt_amt;
        data.ngsup_amt = data.ngsup_amt == null || data.ngsup_amt == "0" || data.ngsup_amt == "null" || data.ngsup_amt == "" ? 0 : data.ngsup_amt;
        data.nil_amt = data.nil_amt == null || data.nil_amt == "0" || data.nil_amt == "null" || data.nil_amt == "" ? 0 : data.nil_amt;
        data.iamt = data.iamt == null || data.iamt == "0" || data.iamt == "null" || data.iamt == "" ? 0 : data.iamt;
        data.camt = data.camt == null || data.camt == "0" || data.camt == "null" || data.camt == "" ? 0 : data.camt;
        data.samt = data.samt == null || data.samt == "0" || data.samt == "null" || data.samt == "" ? 0 : data.samt;
        data.csamt = data.csamt == null || data.csamt == "0" || data.csamt == "null" || data.csamt == "" ? 0 : data.csamt;
  
        if(this.type == 'NIL'){
          data.nil_amt= Number(data.nil_amt.toString().replaceAll(',',''));
          data.expt_amt= Number(data.expt_amt.toString().replaceAll(',',''));
          data.ngsup_amt= Number(data.ngsup_amt.toString().replaceAll(',',''));
  
          if(data.id == 0 || data.id == null){
            data.isAdded = true;
          }
        }
        else if(this.type == 'ADVADJ' || this.type == 'ADVRCD'){
          data.iamt= Number(data.iamt.toString().replaceAll(',',''));
          data.camt= Number(data.camt.toString().replaceAll(',',''));
          data.samt= Number(data.samt.toString().replaceAll(',',''));
          data.csamt= Number(data.csamt.toString().replaceAll(',',''));
          data.gross= Number(data.gross.toString().replaceAll(',',''));
          if((data.pos == null || data.pos == undefined || data.pos == "") && data.isDeleted != true){
            this._snackBar.open('POS is mandatory.', 'OK', {
              duration: GlobalSrvConstantsService.SNACKBARDURATION,
              panelClass: ['alert-snackbar']
            });
            this.calculateData();
            return;
          }
          if((data.rate == null || data.rate == undefined) && data.isDeleted != true){
            this._snackBar.open('Rate is mandatory.', 'OK', {
              duration: GlobalSrvConstantsService.SNACKBARDURATION,
              panelClass: ['alert-snackbar']
            });
            this.calculateData();
            return;
          }
        }
        else if(this.type == 'B2CS' || this.type == 'B2CSA'){
          data.iamt= Number(data.iamt.toString().replaceAll(',',''));
          data.camt= Number(data.camt.toString().replaceAll(',',''));
          data.samt= Number(data.samt.toString().replaceAll(',',''));
          data.csamt= Number(data.csamt.toString().replaceAll(',',''));
          data.taxval= Number(data.taxval.toString().replaceAll(',',''));
          data.applicablerate= Number(data.applicablerate);
          data.rate= Number(data.rate);
          if((data.pos == null || data.pos == undefined || data.pos == "") && data.isDeleted != true){
            this._snackBar.open('POS is mandatory.', 'OK', {
              duration: GlobalSrvConstantsService.SNACKBARDURATION,
              panelClass: ['alert-snackbar']
            });
            this.calculateData();
            return;
          }
          if((data.rate == null || data.rate == undefined) && data.isDeleted != true){
            this._snackBar.open('Rate is mandatory.', 'OK', {
              duration: GlobalSrvConstantsService.SNACKBARDURATION,
              panelClass: ['alert-snackbar']
            });
            this.calculateData();
            return;
          }
          if((data.omon == null || data.omon == undefined || data.omon == "") && data.isDeleted != true && this.type == 'B2CSA'){
            this._snackBar.open('Original Month is mandatory.', 'OK', {
              duration: GlobalSrvConstantsService.SNACKBARDURATION,
              panelClass: ['alert-snackbar']
            });
            this.calculateData();
            return;
          }
        }
        else if(this.type == 'HSN'){
          data.total_qty= Number(data.total_qty.toString().replaceAll(',',''));
          data.val= Number(data.val.toString().replaceAll(',',''));
          data.taxval= Number(data.taxval.toString().replaceAll(',',''));
          data.iamt= Number(data.iamt.toString().replaceAll(',',''));
          data.camt= Number(data.camt.toString().replaceAll(',',''));
          data.samt= Number(data.samt.toString().replaceAll(',',''));
          data.csamt= Number(data.csamt.toString().replaceAll(',',''));
          data.rate= Number(data.rate);
          if((data.code != null && data.code != "" && data.isDeleted != true) && data.code.length < 6){
            this._snackBar.open('HSN Code should have minimum 6 digits.', 'OK', {
              duration: GlobalSrvConstantsService.SNACKBARDURATION,
              panelClass: ['alert-snackbar']
            });
            this.calculateData();
            return;
          }
          else if((data.uqc == "" || data.code == "" || data.rate == null) && data.isDeleted != true){
              this._snackBar.open('HSN Code, UQC and Rate are mandatory', 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['alert-snackbar']
              });
              this.calculateData();
              return;
            }
            var taxCount = (data.taxval * data.rate )/100;
            var igstCount = data.iamt + data.camt + data.samt;
            var diff = taxCount - igstCount ;
            
            if((diff > 1 || diff < -1) && data.isDeleted != true){
              
              this._snackBar.open('Taxable Value * GST Rate should be equals to Tax amount.', 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['alert-snackbar']
              });
              return;
            }

            if((data.uqc != "" && data.rate != 0 && data.code) && data.isDeleted != true){
              
              var count = 0;
              this.dataSource.forEach(element => {
                if(element.uqc == data.uqc && data.rate == element.rate && element.code == data.code){
                  
                  count ++;
                }
              });
              if(count > 1){
                
                this._snackBar.open('UQC,Rate and HSN Code should be unquie combination.', 'OK', {
                  duration: GlobalSrvConstantsService.SNACKBARDURATION,
                  panelClass: ['alert-snackbar']
                });
                return;
              }
            }

          }     
    }

    const status = this.dataSource.some(user => {
      let counter  = 0;
      for (const iterator of this.dataSource) {
        if (iterator.pos === user.pos && iterator.rate === user.rate) {
          counter += 1;
        }
      }
      return counter > 1;
    });

    const status2 = this.dataSource.some(user => {
      let counter  = 0;
      for (const iterator of this.dataSource) {
        if (iterator.pos === user.pos && iterator.applicablerate === user.applicablerate) {
          counter += 1;
        }
      }
      return counter > 1;
    });

    if(status2 == true && (this.type == 'ADVADJ' || this.type == 'ADVRCD')){
      this._snackBar.open('Rate and POS should be unique.', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });
      this.calculateData();
      return;
  }

    if(status == true && (this.type == 'B2CS' || this.type == 'B2CSA')){
        this._snackBar.open('Rate and POS should be unique.', 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['alert-snackbar']
        });
        this.calculateData();
        return;
    }

      this.dataSelectedForEdit.emit(this.editedData);
      this.editedData =[];
      this.isEdit = true;
      this.isHSN99 = true;
    }
  }

  convertToNumber(){
    for(let data of this.editedData){ 
      data.expt_amt = Number(data.expt_amt);
      data.nil_amt = Number(data.nil_amt);
      data.ngsup_amt = Number(data.ngsup_amt);
      data.expt_amt = Number(data.expt_amt); 
    }
  }
  stateCode:string;
  update(id,prop,row,event){
    if(this.type == "ADVADJ" || this.type == "ADVRCD" || this.type == "B2CS" || this.type == "B2CSA"){
      if(prop == 'pos')
      {
        if(this.stateCode == event.value)
        { 
        this.dataSource[id]['supply'] = "INTRA"; 
        }
        else{
          this.dataSource[id]['supply'] = "INTER"; 
        }
      }
    }
    
    let editField;
    if(prop == 'pos' || prop == 'uqc' || prop == 'rate' || prop == 'omon'){
      editField = event.value;
      this.dataSource[id][prop] = editField; 
    }
    else if(prop == 'applicablerate' && this.type.includes("ADV")){
      editField = event.value;
      this.dataSource[id][prop] = editField; 
    }
    else if(prop == 'applicablerate' && this.type.includes("B2CS")){
      editField = event.value;
      this.dataSource[id][prop] = editField; 
    }
    else{
      editField = event.target.value;
    }

    if(prop == 'code' && this.type == 'HSN'){
      if(editField.length == 6 && editField.substring(0,2) == '99'){
        this.isHSN99 = true;
        this.dataSource[id]['description'] = null;
        this.dataSource[id]['uqc'] = 'NA';
        this.dataSource[id]['total_qty'] = 0;
      }
      else{
        this.isHSN99 = false;
      }
    }
    
    if(this.type == "B2CS" || this.type == "B2CSA" || this.type == "HSN" || this.type == "ADVRCD" ){
      if(prop == 'nil_amt' || prop == 'expt_amt' || prop == 'ngsup_amt' || 
      prop == 'total_qty' || prop == 'val' || prop == 'taxval'
      || prop == 'iamt' || prop == 'camt' || prop == 'samt' || prop == 'csamt'
      || prop == 'gross'){
        if (this.isNumber(Number(event.target.value.toString().replaceAll(',','')))) {
          editField =  this.Comma(editField);
          this.dataSource[id][prop] = editField; 
        }
        else if(event.target.value.charAt(0) == "-"){
            if(event.target.value.length == 1){
              editField = event.target.value;
              this.dataSource[id][prop] = editField; 
            }
            else if(event.target.value.length > 1 && this.isNumber(Number(event.target.value.toString().replaceAll(',','')))){
              editField =  this.Comma(editField);
              this.dataSource[id][prop] = editField; 
            }
            else{
              event.target.value= this.dataSource[id][prop].slice(0, -1);  
              return;
            } 
        }
        else{
          event.target.value= this.dataSource[id][prop].slice(0, -1);  
          return;
        } 
      }
    }
    else {
      if(prop == 'nil_amt' || prop == 'expt_amt' || prop == 'ngsup_amt' || 
      prop == 'total_qty' || prop == 'val' || prop == 'taxval'
      || prop == 'iamt' || prop == 'camt' || prop == 'samt' || prop == 'csamt'
      || prop == 'gross'){
        if (this.isNumber(Number(event.target.value.toString().replaceAll(',','')))) {
          editField =  this.Comma(editField);
          this.dataSource[id][prop] = editField; 
        }
        else{
          event.target.value= this.dataSource[id][prop].slice(0, -1);  
          return;
        } 
      }
    }

    if(this.type == "B2CS" || this.type == "B2CSA"){
      if(prop == 'taxval' || prop == 'rate' || prop == 'pos' || prop == 'applicablerate'){
        if(this.stateCode == this.dataSource[id]['pos']){      
          this.dataSource[id]['iamt'] =0;
          if(this.dataSource[id]['applicablerate'] == "65"){
            this.dataSource[id]['camt'] = this.Comma((Number(this.dataSource[id]['taxval'].toString().replaceAll(',','')) * Number(this.dataSource[id]['rate'])) *65/100/2);
            this.dataSource[id]['samt'] = this.Comma((Number(this.dataSource[id]['taxval'].toString().replaceAll(',','')) * Number(this.dataSource[id]['rate']))*65/100/2);
          }
          else{
            this.dataSource[id]['camt'] = this.Comma((Number(this.dataSource[id]['taxval'].toString().replaceAll(',','')) * Number(this.dataSource[id]['rate']))/100/2);
            this.dataSource[id]['samt'] = this.Comma((Number(this.dataSource[id]['taxval'].toString().replaceAll(',','')) * Number(this.dataSource[id]['rate']))/100/2);
          }
         
        }
        else{ 
          this.dataSource[id]['camt'] = 0;
          this.dataSource[id]['samt'] =0;
          if(this.dataSource[id]['applicablerate'] == "65"){
            this.dataSource[id]['iamt'] = this.Comma((Number(this.dataSource[id]['taxval'].toString().replaceAll(',','')) * Number(this.dataSource[id]['rate']))*65/100);
          }
          else{
            this.dataSource[id]['iamt'] = this.Comma((Number(this.dataSource[id]['taxval'].toString().replaceAll(',','')) * Number(this.dataSource[id]['rate']))/100);
          }        
        }
      }
    }
    if(this.type == "ADVADJ" || this.type == "ADVRCD"){
      if(prop == 'applicablerate' || prop == 'gross' || prop == 'pos'){
        if(this.stateCode == this.dataSource[id]['pos']){        
          this.dataSource[id]['iamt'] =0;
          this.dataSource[id]['camt'] = this.Comma((Number(this.dataSource[id]['gross'].toString().replaceAll(',','')) * Number(this.dataSource[id]['applicablerate']))/100/2);
          this.dataSource[id]['samt'] = this.Comma((Number(this.dataSource[id]['gross'].toString().replaceAll(',','')) * Number(this.dataSource[id]['applicablerate']))/100/2);
        }
        else{
          this.dataSource[id]['camt'] = 0;
          this.dataSource[id]['samt'] =0;
          this.dataSource[id]['iamt'] = this.Comma((Number(this.dataSource[id]['gross'].toString().replaceAll(',','')) * Number(this.dataSource[id]['applicablerate']))/100);
        }
      }
    }
    if (!this.editedData.some((item) => item.rowNumber == row.rowNumber)) {
      this.editedData.push(this.dataSource[id]);
    }
    else {
      this.editedData = this.editedData.filter(item => item.rowNumber !== row.rowNumber);
      this.editedData.push(this.dataSource[id]);
    }
  }

  addRow(i){
   let newrow = new SectionViewModel();
   newrow ={
    applicablerate: 0,
    camt: 0,
    cancel: 0,
    code: "",
    csamt: 0,
    customerGstin: "",
    customerName: "",
    description: "",
    doc_type: 0,
    expt_amt: 0,
    from: "",
    gross: 0,
    gstin: this.gstin[0],
    iamt: 0,
    id: 0,
    idt: "",
    inum: "",
    net_issue: 0,
    ngsup_amt: 0,
    nil_amt: 0,
    ntty: "",
    num: 0,
    oidt: "",
    oinum: "",
    period: this.period,
    pos: "",
    rate: 0,
    rowNumber: this.dataSource.length+1,
    samt: 0,
    supply: "",
    taxval: 0,
    to: "",
    total_Record: 0,
    total_qty: 0,
    totalnum: 0,
    transactionType: "",
    uqc: "",
    val: 0,
    isAdded:true,
    isDeleted:false,
    omon:""
   }
   this.dataSource.push(newrow);
   this.editedData.push(newrow);
  }
  deleteRow(id,row){
    var index =  this.dataSource.indexOf(row);
    var edindex =  this.editedData.indexOf(row);
    if(edindex == -1){
      this.editedData.push(this.dataSource[index]);
      edindex =  this.editedData.indexOf(row);
    } 
    this.editedData[edindex].isDeleted = true;  
    this.dataSource.splice(index, 1);

    if(row.id == 0 && row.isDeleted == true){
      this.editedData.splice(edindex, 1);
    }
  }

  Comma(Num) { //function to add commas to textboxes    
    Num += '';
    Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
    Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
   let x = Num.split('.');
   let x1 = x[0];
   let x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1))
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    return x1 + x2;
  }
  
  isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

  uploadFile(){
    let dialogTemplate = new Dialog();
    if(this.type == 'B2CS'){
      dialogTemplate.Type = DialogTypes.B2CSUpload;
      dialogTemplate.Message = "Upload B2CS Summary File.";
    }
    else if(this.type == 'B2CSA'){
      dialogTemplate.Type = DialogTypes.B2CSUpload;
      dialogTemplate.Message = "Upload B2CSA Summary File.";
    }
    else{
      dialogTemplate.Type = DialogTypes.HSNUpload;
    dialogTemplate.Message = "Upload HSN Summary File.";
    }
    const dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
      data: dialogTemplate
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.fileUploadedEvent.emit(result);
    })
  }
}
