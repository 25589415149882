import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { AppConfigService } from '../app-config/app-config.service';
import { OTPUserDetails } from 'src/app/shared/models/get/get-file-returns/get-otp-user-details';


@Injectable({
  providedIn: 'root'
})
export class GenerateAuthTokenService {
  RefreshOTPSessionPage : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _httpClient: HttpClient) { }

  ewayBillAPIBaseUrl = AppConfigService.ApiEnvoiceEwaybillServiceUrl;
  gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;
  activatedGstin: BehaviorSubject<any> = new BehaviorSubject(null);



  getAuthenticationToken(OTPUserDetails: OTPUserDetails): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/AuthGSP/GenerateTokenIfExpired', OTPUserDetails
    );
  }

  getGstinSessionDetails(gstinList: any): Observable<any> {
    return this._httpClient.post(
        this.gstrServiceBaseUrl + '/AuthGSP/CheckSessionStatusGSTIN', gstinList
    )
}
}
