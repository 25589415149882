import { Injectable } from '@angular/core';
import { GlobalApiService } from '../../global/global-api/global-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class GetEInvoiceService {

    baseUrl = GlobalApiService.GLOBALAPIURL;

    constructor(private _httpClient: HttpClient) { }

    getRequestData(currentPage: number, pageSize: number,requestType : string): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/ei/request?currentPage=' + currentPage + '&pageSize=' + pageSize + '&requestType=' + requestType);
    }

    getEInvoiceRequestDetail(requestID: number, pageSize: number, currentPage: number,requestType : string): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/ei/snapshot/header?requestId=' + requestID + '&currentPage=' + currentPage + '&pageSize=' + pageSize + '&requestType=' + requestType);
    }

    getEInvoiceSnapshotLineItem(headerID: number, pageSize: number, currentPage: number): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/ei/snapshot/line?headerId=' + headerID + '&currentPage=' + currentPage + '&pageSize=' + pageSize);
    }

    getCancelReasons(): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/ei/cancel/reason');
    }

    getCancelRequestData(currentPage: number, pageSize: number): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/ei/cancel/request?currentPage=' + currentPage + '&pageSize=' + pageSize);
    }
    getEICancelRequestDetails(requestID: number, pageSize: number, currentPage: number): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/ei/cancel/snapshot?requestId=' + requestID + '&currentPage=' + currentPage + '&pageSize=' + pageSize);
    }
}
