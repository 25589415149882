import { Component, OnInit, ViewChild, TemplateRef, Inject } from '@angular/core';
import { GlobalSrvConstantsService } from 'src/app/shared/services/global/global-constants/global-srv-constants.service';
import { BehaviorSubject } from 'rxjs';
import { DataColumnList } from 'src/app/shared/models/get/get-md-global/get-column-list';
import { GetAnx1DetailsService } from 'src/app/shared/services/get/get-srv-anx1/get-anx1-details.service';
import { GetSrvSharedService } from 'src/app/shared/services/get/get-srv-global/get-srv-shared.service';
import { MatSnackBar, MatDialog, MatTableDataSource, MatPaginator, MAT_DIALOG_DATA } from '@angular/material';
import { GSTR1SummaryResponseModel, TransactionType, GSTR1SummaryDownloadViewModel, GSTR1RemoveTransactionalDataViewModel, GSTR1ViewSummaryResponseModel, PrepareReturnModel } from 'src/app/shared/services/Returns/returns-model.service';
import { ReturnsService } from 'src/app/shared/services/Returns/gstr-one.service';
import { BoConfirmationDialogComponent } from 'src/app/shared/bo-confirmation-dialog/bo-confirmation-dialog.component';
import { Dialog, DialogTypes } from 'src/app/shared/Constants';
import { GbPagination } from 'src/app/shared/models/global/pagination/gb-pagination';

@Component({
  selector: 'app-sub-gstr-one-summary-grid',
  templateUrl: './sub-gstr-one-summary-grid.component.html',
  styleUrls: ['./sub-gstr-one-summary-grid.component.css']
})
export class SubGstrOneSummaryGridComponent implements OnInit {
  pageSize: number = 5;
  currentPage: number = 1;
  id: any;
  isDelete:boolean=false;
  reasonfordeletion:string="";
  isExport:boolean=false;
  waitingEInvoiceResponse = false;
  entityGstin:string;
  reportedPeriod:string;
  summaryDownloadViewModel:GSTR1SummaryDownloadViewModel = new GSTR1SummaryDownloadViewModel();
  dataColumns: DataColumnList[] = [];
  summaryData:GSTR1SummaryResponseModel[]=[];
  listOfTransactions:any[]=[{Name:"B2B",value:"B2B"},{Name:"B2CL",value:"B2CL"},{Name:"B2CS",value:"B2CS"},{Name:"CDNR",value:"CDNR"},{Name:"CDNUR",value:"CDNUR"},{Name:"EXP",value:"EXP"}]
  TransactionTypeFilter =["B2B","B2CL","B2CS","CDNR","CDNUR","EXP"];
  selectedTransactionType:any[]=[]
  showTable:boolean = false;
  dataList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  columnList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dataListPageCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dataListSelection: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isTableMultiSelect: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public gstr1ViewSummaryDataDisplayedColumns: string[] = [];

  

  gstr1SummaryDataSource = new MatTableDataSource<GSTR1ViewSummaryResponseModel>(null);
  pageParameters: GbPagination = {} as GbPagination;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  
  constructor(private _snackBar:MatSnackBar ,private dialog:MatDialog , private returnService: ReturnsService, private getColumnList: GetSrvSharedService, private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data) {

      this.entityGstin = data.GSTIN;
      this.reportedPeriod = data.reportedPeriod;

     }
  @ViewChild('reasonDialog', { static: true }) reasonDialog: TemplateRef<any>;
  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }
  
  ngOnInit() {

    this.gstr1ViewSummaryDataDisplayedColumns = ['EntityGSTIN','GSTR1Period', 'TransactionCategory', 'DataUploadedOn','GSTR1SummaryPreparedOn','GetGSTR1eInvoiceOn','CountOfDocuments','TotalTaxableValue','TotalCGST','TotalSGST','TotalIGST'];
    this.gstr1SummaryDataSource.data = [];
    this.ViewGSTR1Summary();
    // this.returnService.ReceiveGSTR1Summary.subscribe((res: GSTR1SummaryResponseModel[]) => {
    //   if (res != null) {
    //     this.summaryData = res;
    //     this.showTable = true;
    //     this.dataList.next(this.summaryData);
    //     this.dataListPageCount.next(res.length);
    //   }
    // })
    // this.returnService.GetSummary.subscribe((res:GSTR1SummaryDownloadViewModel)=>{
    //   this.summaryDownloadViewModel = res;
    // })

    // this.getDisplayedColumnList("GSTR1Summary");
    // this.isTableMultiSelect.next(true);
  }


  ViewGSTR1Summary()
  {
    let prepareReturnsModel = new PrepareReturnModel();
    let entities: string[] = [];
    entities.push(this.entityGstin);
    prepareReturnsModel.EntityGstin = entities;
    prepareReturnsModel.ReportedPeriod = this.reportedPeriod;
    prepareReturnsModel.TransactionType = "viewSummary";

    this.returnService.getGSTR1ViewSummary(prepareReturnsModel).subscribe((data) => {
      this.gstr1SummaryDataSource.data = data.data.result;
    });
  }

  getDisplayedColumnList(tableType: string) {
    this.getColumnList.getColumnList(tableType).subscribe
      (
        data => {
          if ('200'.localeCompare(data.status) === 0) {
            this.dataColumns = data.data;
            this.columnList.next(data.data);
          }
        },
        error => {
          let errorStatus;
          let errorMessage;
          if (error.error.message !== undefined) {
            errorStatus = error.error.status;
            errorMessage = error.error.message.substring(0, 90);
          } else {
            errorStatus = error.status;
            errorMessage = error.error;
          }
          this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
            duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['danger-snackbar']
          });
          setTimeout(() => { this.waitingEInvoiceResponse = false; }, 1000);
        }
      );
  }

  transactionTypeSelection(event:any)
  {
  this.selectedTransactionType = event;
  let data = this.summaryData.filter(x => this.selectedTransactionType.findIndex(y => y == x.transactionCategory)!= -1)
  this.dataList.next(data);
  }

  receiveSummaryPageEvent(event: any) {
  }

  receiveDataSelection(event: any) {
  this.selectedTransactionType = event.selected.map(u => u.transactionCategory)
  }

  downloadPdf()
  {this.isExport = true;
    this.returnService.summarydownload(this.summaryDownloadViewModel).subscribe((res)=>{
      this.isExport = false;
      var fileName = "gstr1summary" + Date.now();
      var _type = 'application/pdf';
      const blob = new Blob([this.convertbase64toArrayBuffer(res.stream)], { type: _type });
      var a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
        this._snackBar.open('File Downloaded Successfully', 'OK', {
            duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['success-snackbar']
        });
  },(err)=>{
    this.isExport = false;
    this._snackBar.open('Error occured while removing the data. Please try again', 'OK', {
      duration: GlobalSrvConstantsService.SNACKBARDURATION,
      panelClass: ['danger-snackbar']
  });
  });
  }

removeTransactionalData()
{
if(this.selectedTransactionType.length == 0)
{
  this._snackBar.open('Please select atleast one transaction type.', 'OK', {
    duration: GlobalSrvConstantsService.SNACKBARDURATION,
    panelClass: ['danger-snackbar']
});
}
else{
  let dialogTemplate = new Dialog();
  dialogTemplate.Type = DialogTypes.Confirmation;
  dialogTemplate.Message = "Are you sure do you want to remove transactional data of selected transaction type?";

  const dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
      data: dialogTemplate, disableClose: true
  });
  dialogRef.afterClosed().subscribe((res)=>{
    let dialogTemplate = new Dialog();
  dialogTemplate.Type = DialogTypes.RemoveTransactionalData;

  const reasondialogref = this.dialog.open(BoConfirmationDialogComponent, {
      data: dialogTemplate, disableClose: true
  });
  reasondialogref.updateSize('20%');
  reasondialogref.afterClosed().subscribe((res)=>{
  if(res =='' || res == undefined)
  {
    this._snackBar.open('Reason cannot be empty', 'OK', {
      duration: GlobalSrvConstantsService.SNACKBARDURATION,
      panelClass: ['danger-snackbar']
  });
  }
  else{
  this.isDelete = true;
  let removeData = new GSTR1RemoveTransactionalDataViewModel();
  removeData.entityGstin = this.summaryDownloadViewModel.entityGstin;
  removeData.reportedPeriod = this.summaryDownloadViewModel.reportedPeriod
  removeData.transactionTypes = this.selectedTransactionType;
  removeData.reason = res;
  this.returnService.removeTransactionalData(removeData).subscribe((response)=>
  {
    this.isDelete = false;
    this._snackBar.open('Data has been removed Successfully', 'OK', {
      duration: GlobalSrvConstantsService.SNACKBARDURATION,
      panelClass: ['success-snackbar']
  });
  },(err)=>{
    this.isDelete = false;
    this._snackBar.open('Error occured while removing the data. Please try again', 'OK', {
      duration: GlobalSrvConstantsService.SNACKBARDURATION,
      panelClass: ['danger-snackbar']
  });
  });
  }
  });
  })
}
}


  convertbase64toArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
    }

    public ngAfterViewInit() {
      // this.sort.sortChange.subscribe((event) => {
      //   this.gstinsDataSource.data = this.getSortedData();
      // });
      this.gstr1SummaryDataSource.paginator = this.paginator;
    }
    goTo(p: number) { this.paginator.pageIndex = p; }
  
    getPageEvent(event) {
      this.pageParameters.pageSize = event.pageSize;
      this.pageParameters.currentPage = event.pageIndex + 1;
    }

    doFilter(ev){

    }

}
