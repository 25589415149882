import { Component, OnInit } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { GlobalApiService } from './shared/services/global/global-api/global-api.service';
import { IdleTimeOutSettings, Dialog, DialogTypes } from './shared/Constants';
import { BoConfirmationDialogComponent } from './shared/bo-confirmation-dialog/bo-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { SrvAuthService } from './shared/services/global/global-auth-service/srv-auth.service';
@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.css'],
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    title = 'Bolt On | Eway';
    dialogRef:any;
    constructor(private authService: SrvAuthService,private userIdle: UserIdleService, private globalsrvc: GlobalApiService, private dialog: MatDialog) { }

    ngOnInit(): void {
        this.userIdle.startWatching();

        this.userIdle.onTimerStart().subscribe(() => {
            var eventList = ["click", "mouseover", "keydown", "DOMMouseScroll", "mousewheel",
                "mousedown", "touchstart", "touchmove", "scroll", "keyup"];
            for (let event of eventList) {
                document.body.addEventListener(event, () => {
                    this.userIdle.resetTimer()
                });
            }
        });

        this.userIdle.onTimeout().subscribe((res) => {
            let dialogTemplate = new Dialog();
            dialogTemplate.Type = DialogTypes.IdleTime;
            dialogTemplate.Message = "Your session will be expired in";
            // if(this.dialog.openDialogs && this.dialog.openDialogs.length > 0){
            //     return false;
            //   }
            if(this.dialogRef == null){
                this.dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
                    data: dialogTemplate, disableClose: true
                });
                this.dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        this.userIdle.resetTimer()
                    }
                    else {
                    this.globalsrvc.InactiveSession.next(true)
                    this.userIdle.stopWatching();
                    }
                    this.dialogRef = null;
                })
            }
        });
    }
}
