import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatSnackBar, MatDialog, MatDialogConfig } from '@angular/material';
import { AppConfigService } from '../../common/app-config/app-config.service';
import { OTPUserDetails } from 'src/app/shared/models/get/get-file-returns/get-otp-user-details';
import { GlobalSrvConstantsService } from '../../global/global-constants/global-srv-constants.service';
import { OtpConfirmationDialogComponent } from 'src/app/shared/otp-confirmation-dialog/otp-confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ActivateGstinService {

  constructor(private _httpClient: HttpClient, private snackBar: MatSnackBar, public dialog: MatDialog) { }
  ewayBillAPIBaseUrl = AppConfigService.ApiEnvoiceEwaybillServiceUrl;
  gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;
  // otpUserDetails: BehaviorSubject<any> = new BehaviorSubject(null);
  activatedGstin: BehaviorSubject<any> = new BehaviorSubject(null);


  // Otp Generation Request
  getSessionId(GSTIN: string) {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/AuthGSP/GenerateOTPRequest' + '/' + GSTIN
    ).pipe(
      tap(response => { this.handleResponse(response, GSTIN) })
    )
  }





  removeSession(GSTIN: string) : Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/AuthGSP/RemoveSession' + '/' + GSTIN
    )
  }

  download(): Observable<any> {
    
    return this._httpClient.get(
        this.gstrServiceBaseUrl + '/AuthGSP/DownloadGSTSession', { responseType: 'blob' }
    );
  }
  
  handleResponse(response, GSTIN) {
    const otpUserDetails: OTPUserDetails = {} as OTPUserDetails;
    if (response && response.data) {
      otpUserDetails.SessionId = response.data;
      otpUserDetails.EntityGstin = GSTIN;
      this.openOTPDialogBox(otpUserDetails);

    }
    else {
      this.snackBar.open( response ? response.message : "No response from service", 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });
    }
  }

  openOTPDialogBox(otpUserDetails: OTPUserDetails) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      otpUserDetails
    };
    const dialogRef = this.dialog.open(OtpConfirmationDialogComponent, dialogConfig);
    dialogRef.updateSize('50%', '40%');
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  returnActivatedGstin(res) {
    this.activatedGstin.next(res);
  }

}
