import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor() { }

  public static ApiEnvoiceEwaybillServiceUrl = environment.einvoiceEwayBillServiceUrl;
  public static ApiIdentityManagementServiceUrl = environment.identityManagementServiceUrl;
  public static ApiuserManagementServiceUrl =  environment.userManagementServiceUrl;
  public static ApiInfrastructureServiceUrl = environment.infrastructureServiceUrl;
  public  static ApiGstrServiceUrl = environment.gstrServiceUrl;
  public static ReturnServiceUrl = environment.returnServiceUrl;
  public static ReportServiceURL = environment.reportServiceURL;
}
