import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { InputServiceDistributionService } from '../../../shared/services/gstr6/input-service-distribution.service';
import { MatSnackBar, MatAccordion, MatSort } from '@angular/material';

@Component({
  selector: 'app-isd-plan-popup',
  templateUrl: './isd-plan-popup.component.html',
  styleUrls: ['./isd-plan-popup.component.css']
})
export class IsdPlanPopupComponent implements OnInit {
  gstin: string;
  period: string;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[];
  dataSource = new MatTableDataSource<any>(null);
  isEditMode: boolean;
  isEnableField: boolean;
  editModeMessage: string = '';
  controls: FormArray;
  isdPlansList: any;
  commentsFormGroup: FormGroup;
  isSubmit: boolean;

  constructor(
    private readonly inputServiceDistributionService: InputServiceDistributionService,
    private readonly snackbarService: MatSnackBar,
    private dialogRef: MatDialogRef<IsdPlanPopupComponent>,
    private readonly formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {
    this.gstin = this.data && this.data.gstin && this.data.gstin.length > 0 ? this.data.gstin : '';
    this.period = this.data && this.data.period && this.data.period.length > 0 ? this.data.period : '';
    this.getGridColumns();
    this.dataSource.data = [];
    this.getISDPlans(false);
    this.commentsFormGroup = this.formBuilder.group({
      Comments: ['', [Validators.required, Validators.maxLength(250)]],
      TotalRevenue: ['']
    });
    this.commentsFormGroup.disable();
  }

  initFormGroup() {
    const toGroups = this.isdPlansList.map(entity => {
      return new FormGroup({
        ISDDistributionMappingID: new FormControl(entity.ISDDistributionMappingID),
        Revenue: new FormControl(entity.NetRevenue, [Validators.required, Validators.min(0), Validators.max(100)])
      }, { updateOn: "blur" });
    });
    this.controls = new FormArray(toGroups);
  }

  getISDPlans(isPrevMonth) {
    if (this.gstin && this.gstin.length > 0 && this.period && this.period.length > 0) {
      this.inputServiceDistributionService.getISDPlans(this.gstin, this.period, isPrevMonth).subscribe(response => {
        if ('200'.localeCompare(response.status) === 0 && response.data && response.data.length > 0) {
          this.isdPlansList = JSON.parse(response.data);
          this.dataSource.data = JSON.parse(response.data);
          this.initFormGroup();
          this.editModeMessage = this.isdPlansList[0].isEditMessage;
          this.f.TotalRevenue.setValue(this.isdPlansList.reduce((sum, current) => sum + +current.NetRevenue, 0));
          const editableRecords = this.isdPlansList.filter(x => x.isEditFlag);
          if (editableRecords && editableRecords.length > 0) {
            this.isEditMode = true;
            this.f.Comments.enable();
          }
        }
      });
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  goTo(p: number) { this.paginator.pageIndex = p; }

  getGridColumns() {
    this.displayedColumns = [
      'ISDGSTIN', 'ReturnPeriod', 'DistributedGSTIN', 'Revenue'
    ];
  }

  Filter(event: Event, dataSource: MatTableDataSource<any>) {
    const filterValue = (event.target as HTMLInputElement).value;
    dataSource.filter = filterValue.trim().toLowerCase();

    if (dataSource.paginator) {
      dataSource.paginator.firstPage();
    }
  }

  applyFilter(event: Event) {
    this.Filter(event, this.dataSource)
  }


  getControl(fieldName, isdDistributionMappingID) {
    const index = this.controls.value.findIndex(x => x.ISDDistributionMappingID == isdDistributionMappingID);
    return this.controls.at(index).get(fieldName) as FormControl;
  }

  enableFields() {
    this.isEnableField = true;
  }

  get f() { return this.commentsFormGroup.controls; }

  saveData() {
    this.isSubmit = true;
    if (this.controls.valid && this.commentsFormGroup.valid) {
      const sum = this.controls.value.reduce((sum, current) => sum + +current.Revenue, 0);
      if (sum && sum != 100) {
        this.snackbarService.open('Total Revenue must be 100', 'Ok');
        return;
      }
      this.inputServiceDistributionService.saveISDPlans(this.controls.value, this.f.Comments.value).subscribe(response => {
        if ('200'.localeCompare(response.status) === 0) {
          this.snackbarService.open('ISD Plans saved successfully', 'Ok');
          this.close(true);
        }
        this.isSubmit = false;
      });
    }
  }

  calculateTotalRevenue() {
    this.f.TotalRevenue.setValue(this.controls.value.reduce((sum, current) => sum + +current.Revenue, 0));
  }

  getPrevMonthPlan(event) {
    if (event.checked)
      this.getISDPlans(true);
    else
      this.getISDPlans(false);
  }

  close(isSave): void {
    this.dialogRef.close(isSave);
  }
}
