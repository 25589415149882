import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../common/app-config/app-config.service';
import { GlobalApiService } from '../global/global-api/global-api.service';
import {
    SearchTaxNotice,SaveTaxNotice,TaxNotice, additionalTaxNoticeUpdate, tempfiletoServer
} from '../../../shared/models/post/pst-md-tax-notice/post-search-tax-notice';
import { TaxNoticeRequest } from '../../models/global/boew-eway/get-boew-eway-search';

@Injectable({
    providedIn: 'root'
})
export class TaxNoticeService {

    baseUrl = GlobalApiService.GLOBALAPIURL;
    oganizationDetail = GlobalApiService.ORGANIZATIONDATA;
    gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;
    constructor(private _httpClient: HttpClient) { }

    getGstinFPList(): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/bo/inv/filter'
        );
    }

    getTaxNotices(taxNotice:SearchTaxNotice): Observable<any> {
        return this._httpClient.post(
          this.baseUrl + '/TaxNotice/getTaxNotices/',taxNotice);
        }

        getadditionalNotices(taxNotice:SearchTaxNotice): Observable<any> {
            return this._httpClient.post(
              this.baseUrl + '/TaxNotice/getAdditionalNotices/',taxNotice);
            }

        exportTaxNotices(taxNotice:SearchTaxNotice): Observable<any> {
            return this._httpClient.post(
              this.baseUrl + '/file/exportTaxNotices/',taxNotice);
            }

            exportAdditonalNotices(taxNotice:SearchTaxNotice): Observable<any> {
                return this._httpClient.post(
                  this.baseUrl + '/file/exportAdditionalNotices/',taxNotice);
                }

                
            updateStatus(taxNotices:SaveTaxNotice[]): Observable<any> {
              return this._httpClient.post(
                this.baseUrl + '/TaxNotice/UpdateStatus/',taxNotices);
              }

              saveTaxNotices(taxNotices:TaxNotice[]): Observable<any> {
                return this._httpClient.post(
                  this.baseUrl + '/TaxNotice/SaveTaxNotices/',taxNotices);
                }
                saveTaxNoticeResponsesDetails(taxNotices:TaxNoticeRequest): Observable<any> {
                  return this._httpClient.post(
                    this.baseUrl + '/TaxNotice/saveTaxNoticeResponsesDetails/',taxNotices);
                  }

                  getNoticesRepliesAndResponses(id:number,type:string): Observable<any> {
                    return this._httpClient.get(
                      this.baseUrl + '/TaxNotice/getNoticesRepliesAndResponses?id='+id+'&type='+type);
                    }  
                    getTaxNoticeAttachments(id:number,type:string): Observable<any> {
                      return this._httpClient.get(
                        this.baseUrl + '/TaxNotice/GetTaxNoticeAttachmentDetails?id='+id+'&type='+type);
                      }  
                      TaxNoticeOperation_SingleFile(opeartionType:string,fileId:number, taxNoticeid:number,folder:string,filename:string,type:string): Observable<any> {
                       
                        return this._httpClient.get(
                        this.baseUrl + '/TaxNotice/TaxNoticeOperation?opeartionType='+opeartionType+'&fileId='+fileId+'&taxNoticeid='+taxNoticeid+'&folder='+folder+'&filename='+filename+'&type='+type, {         
                          responseType: 'blob'
                      });
                    }
                    TaxNoticeOperation_SingleFile_Delete(opeartionType:string,fileId:number, taxNoticeid:number,folder:string,filename:string,type:string): Observable<any> {
                      
                      return this._httpClient.get(
                      this.baseUrl + '/TaxNotice/TaxNoticeOperation?opeartionType='+opeartionType+'&fileId='+fileId+'&taxNoticeid='+taxNoticeid+'&folder='+folder+'&filename='+filename+'&type='+type);
                  }
                        TaxNoticeOperation_AllFiles(id:number,type:string): Observable<any> {
                          return this._httpClient.get(
                            this.baseUrl + '/TaxNotice/DownloadAllTaxNoticeAttachmentFile?id='+id+'&type='+type,{
                              responseType: 'blob'
                            }
                            )
                          }

                          UploadMultiTaxNoticesFiles(formdata:any): Observable<any> {
                            return this._httpClient.post(
                              this.baseUrl + '/TaxNotice/TaxNoticeMultiUploadAttachments',formdata);    
                          }
                          saveAdditionalTaxNotices(additionalNoticeDate:additionalTaxNoticeUpdate[]): Observable<any>{
                            return this._httpClient.post(
                              this.baseUrl + '/TaxNotice/UpdateAdditionalTaxNoticesDetails',additionalNoticeDate);   
                          }
                          saveTaxNoticesNonUser(taxNotices:TaxNotice[]): Observable<any> {
                            return this._httpClient.post(
                              this.baseUrl + '/TaxNotice/saveTaxNoticesNonUser/',taxNotices);
                            }
                            TaxNoticeOperationSaveTempFile(taxNotices:tempfiletoServer[]): Observable<any> {
                      
                              return this._httpClient.post(
                                this.baseUrl + '/TaxNotice/SaveTaxNoticesTempFiles/',taxNotices);
                              }
                          
                    

   
}
