import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
    providedIn: 'root'
})
export class SrvAuthService {

    public oAuthService: OAuthService;

    constructor() { }
}
