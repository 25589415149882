import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { SrvAuthService } from '../shared/services/global/global-auth-service/srv-auth.service';

@Component({
    selector: 'app-bo-login',
    styleUrls: ['./bo-login.component.css'],
    templateUrl: './bo-login.component.html'
})
export class BoLoginComponent implements OnInit {
    @Input() oAuthService: OAuthService;

    loginForm = this.fb.group({
        password: [null, Validators.required],
        username: [null, Validators.required]
    });

    enableLogin = false;

    constructor(
        private authService: SrvAuthService,
        private router: Router,
        private fb: FormBuilder
    ) {
        this.enableLogin = false;

        this.authService.oAuthService.events.subscribe(event => {
            if (event instanceof OAuthErrorEvent) {
                this.enableLogin = true;
            } else {
                if ('token_received'.localeCompare(event.type) === 0) {
                    this.enableLogin = false;
                    if (this.router.url.startsWith('/login')) {
                        this.router.navigate(['']);
                        localStorage.setItem("login", "true");
                    }
                } else {
                    this.enableLogin = true;
                 
                }
            }
        });

        this.authService.oAuthService.loadDiscoveryDocument().then(() =>{ 
            this.authService.oAuthService.tryLogin().then(_ =>{                        
                if (!this.authService.oAuthService.hasValidIdToken() || !this.authService.oAuthService.hasValidAccessToken()) {
                    this.authService.oAuthService.initImplicitFlow();
                 }
                 else{
                     this.router.navigate(['/']);
                 }                  
             })
         })
    }

    ngOnInit(): void {
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.enableLogin = false;
        if (this.authService.oAuthService.hasValidIdToken()) {
            this.router.navigate(['']);
        } else {
            // Clear Session Variables
            this.enableLogin = true;
        }
    }

    login() {
        this.enableLogin = false;
       // localStorage.setItem("login", "true");
        sessionStorage.removeItem('Organization');
        this.authService.oAuthService.initImplicitFlow();
    }

    onSubmit() {
        alert('Development In Progress!');
    }
}
