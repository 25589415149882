import { Component, OnInit, Inject, ViewChild, AfterViewInit, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatTableDataSource, MatPaginator, MatSnackBar, MatDialogRef } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { GbPagination } from 'src/app/shared/models/global/pagination/gb-pagination';
import { GstinSessionDetails } from 'src/app/shared/models/get/get-file-returns/get-otp-user-details';
import { GlobalSrvConstantsService } from 'src/app/shared/services/global/global-constants/global-srv-constants.service';
import { FileReturnsService } from 'src/app/shared/services/Returns/file-returns/file-returns.service';
import { ActivateGstinService } from 'src/app/shared/services/common/gstin-session/activate-gstin.service';
import { GenerateAuthTokenService } from 'src/app/shared/services/common/gstin-session/generate-auth-token.service';


@Component({
  selector: 'app-file-returns-item-dialog',
  templateUrl: './file-returns-item-dialog.component.html',
  styleUrls: ['./file-returns-item-dialog.component.css']
})


export class FileReturnsItemDialogComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['select', 'entityGstin', 'actions'];
  selection = new SelectionModel<GstinSessionDetails>(true, []);
  dataSource = new MatTableDataSource<GstinSessionDetails>(null);

  selectedReturnType: string;
  sessionDetails: GstinSessionDetails
  selectedGstinList: GstinSessionDetails[];
  selectedGStin: string[];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  pageParameters: GbPagination = {} as GbPagination;
  isSecondRequired: false;
  isPreparing: boolean = false;
  gstinList : string[] =[];
  element:any;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data,
    private fileReturnsService: FileReturnsService,
    public dialogRef: MatDialogRef<FileReturnsItemDialogComponent>,
    private activateGstinService: ActivateGstinService,
    private generateAuthTokenService: GenerateAuthTokenService,
    private injector: Injector) { 
      
    }

  ngOnInit() {
    this.dataSource.data = this.data.sessionDetails;
    this.selectedReturnType = this.data.returnType;

  }

  onActivateClick(entityGstin: string) {
    this.isPreparing = true;
    this.activateGstinService.getSessionId(entityGstin).subscribe((res) => {
     });
    this.isPreparing = false;
  }

  saveAndPrepare() {
    if (this.selection.selected.length === 0) {
      this.snackBar.open('Select at least one Gstin', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });
      return;
    }
    this.selectedGStin = [];
    this.selectedGstinList = this.selection.selected;
    this.selectedGstinList.forEach(element => {
      this.selectedGStin.push(element.entityGstin);
    });
    this.activateGstinService.activatedGstin.next(this.selectedGstinList);
    this.dialogRef.close(false);

  }

  onRefresh(){
    this.gstinList.splice(0); 
    this.data.sessionDetails.forEach(element => {
      this.gstinList.push(element.entityGstin);
    });

    this.generateAuthTokenService.getGstinSessionDetails(this.gstinList).subscribe((data)=>{
      this.dataSource.data = data;
    });
    
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: GstinSessionDetails) {
    // if (!row) {
    //     return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    // }
    // return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row. + 1}`;
  }

  // selectRow(row) {
  // }
  public ngAfterViewInit() {
    // this.sort.sortChange.subscribe((event) => {
    //   this.gstinsDataSource.data = this.getSortedData();
    // });
    this.dataSource.paginator = this.paginator;
  }
  goTo(p: number) { this.paginator.pageIndex = p; }

  getPageEvent(event) {
    this.pageParameters.pageSize = event.pageSize;
    this.pageParameters.currentPage = event.pageIndex + 1;
  }

}
