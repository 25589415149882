import { fileExtensions } from '../Constants';

export function downloadFile(data, fileName) {
    try {
        const lastINdex = fileName.lastIndexOf('.');
        const filetype = getFileContentType(fileName.substr(lastINdex, fileName.length));
        const blob = new Blob([data], { type: filetype });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
        }
    } catch (err) {
        console.error(err);
        console.error(`Error while downloading file`);
    }
}

function getFileContentType(extension): string {
    let fileType: string;

    switch (extension) {
        case fileExtensions.PDF:
            fileType = 'application/pdf';
            break;
        case fileExtensions.ZIP:
            fileType = 'application/zip';
            break;
        case fileExtensions.DOC:
            fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            break;
        case fileExtensions.EXCEL:
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            break;
        default:
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            break;
    }
    return fileType;
}

export function convertbase64toArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
}
