
import {formatDate} from '@angular/common';
import { DatePipe } from '@angular/common';

export function getCurrentDateTimeForFileName(){
    return formatDate(new Date(), 'yyyy_MM_dd_HH_mm_ss', 'en');
}

export function getGridDateTime(dateTime){
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(dateTime, 'dd/MM/yyyy');
}

export function getCurrentFinancialYear() {
    var fiscalyear = "";
    var today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
    } else {
      fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
    }
    return fiscalyear
  }
